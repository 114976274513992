import axios from 'axios'

class DataServicesClass {

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('itoken')
        };
    }

    updateData = (id, data) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/update_data'
                axios.post(API_PATH,
                    {
                        id, data
                    },
                    {
                        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                    }
                ).then((data) => {
                    if (data.status === 200) {
                        resolve(data.data)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }
}

const DataServices = new DataServicesClass;

export default DataServices;
