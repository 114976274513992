import Auth from "helpers/Auth";
import React from "react";

export default function PermissionsGate(props) {

    const ROLES = Auth.getAuth();
    const PERMISSIONS = Auth.getAuthPermissions().map(i => i.toLowerCase());

    var intersections = PERMISSIONS.filter(e => props.hasPermission.map(i => i.toLowerCase()).indexOf(e) !== -1);

    const permissionGranted = PERMISSIONS.includes("All".toLowerCase()) ? true : (props.hasPermission && props.hasPermission.length == 0) ? true : intersections.length > 0 ? true : false;

    if (!permissionGranted && !props.errorProps && !props.isFunction) return <></>;

    if (!permissionGranted && props.errorProps && !props.isFunction)
        return React.cloneElement(props.children, { ...props.errorProps });

    if (props.isFunction == true) {
        return permissionGranted;
    }

    return <>{props.children}</>;

}