import React, { useState } from "react";
import { useTable } from "react-table";
import axios from "axios";
import QRCode from "qrcode.react"



import {
    Redirect
} from "react-router-dom";
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Button,
    Media,
    Pagination,
    PaginationItem,
    Table,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from "reactstrap";
import { issuanceLogs_screen } from "../content";
// core components
import Header from "../components/Headers/ConnectionHeader.js";
import Auth from '../helpers/Auth';
import jwt from "jsonwebtoken";
import qrcode from "qrcode.react";
import PermissionsGate from "../components/Permissions/PermissionsGate";
import PERMISSIONS from "../components/Permissions/Permissions";

var moment = require('moment');
const fetch = require('node-fetch');

let connectionsList = [];

const TOTAL_NUMBER_OF_RECORD_PER_PAGE = 20;
var pageNo = 1;
var filteredList = [];

function ReactTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });

    // Render the UI for your table
    return (
        <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
            <table className="align-items-center table-flush table responsive" {...getTableProps()} >
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) =>
                                column.hideHeader === false ? null : (
                                    <th scope="col" {...column.getHeaderProps()}>{column.render("Header")}</th>
                                )
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export const signout = () => {
    Auth.signout();
    window.location.reload();
};

const IssuanceLogs = (props) => {

    var isPaginationApplied = false;

    const [totalRecords, setTotalRecords] = useState(0);
    const [filterKeywords, setFilterKeywords] = useState({});

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
    const [validateVerificationData, setValidateVerificationData] = useState({})
    const [credential, setCredential] = useState({})
    const toggleValidateModal = () => setIsValidateModalOpen(!isValidateModalOpen);


    const [isQRCodeLoading, setIsQRCodeLoading] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    //  const [validateVerificationData, setValidateVerificationData] = useState({})
    //   const [credential, setCredential] = useState({})
    const toggleQRCodeModal = () => setIsQRCodeModalOpen(!isQRCodeModalOpen);
    const [qrCodeImg, setQrCodeImg] = useState("")

    const [userConnectionId, setUserConnectionId] = useState("")


    const iToken = localStorage.getItem("itoken")
    let decodedToken;
    try {
        decodedToken = jwt.decode(iToken)
    } catch (e) {
        console.log({ e })
    }

    const isIssuer = decodedToken["role"] === "issuer";
    const isVerifier = decodedToken["role"] === "verifier";
    const isAdmin = decodedToken["role"] === "admin";
    const tenantId = decodedToken["tenantId"];

    React.useEffect(() => {
        let firstLoad = localStorage.getItem('firstLoad');
        if (firstLoad === 'true') {
            let fromDate = moment().format("YYYY-MM-DD")
            // let data = loadInitialConnectionList(fromDate, fromDate);
            let data;
            if (data !== undefined) {
                data.then((d) => {
                    localStorage.setItem('table', JSON.stringify(d));
                    connectionsList = d;
                    // setTableData(connectionsList);
                });
            }
            localStorage.setItem('firstLoad', false);
        } else {
            let t = localStorage.getItem('table');
            connectionsList = JSON.parse(t);
            //  setTableData(connectionsList);
        }
    }, []);
    /// New View Work here Start

    const generateQRCode = async (rowIndex, cell) => {
        let verification = filteredList[rowIndex];
        setUserConnectionId(verification.connectionId)
        try {
            setIsQRCodeLoading(true)
            setIsQRCodeModalOpen(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search_issuance_log_Id`,
                {
                    params: { id: verification._id },
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                }
            );


            let qrCodeObj = {};
            if (response.data.connection[0].version) {
                switch (response.data.connection[0].version) {
                    case 3:
                        // Handling v3 QR
                        const credValues = JSON.parse(Buffer.from(response.data.connection[0].encodedData, 'base64').toString());
                        const orderedData = Object.keys(credValues)
                            .sort()
                            .reduce((obj, key) => {
                                obj[key] = credValues[key];
                                return obj;
                            }, {});
                        let qrObj = {
                            d: Object.values(orderedData),
                            id: response.data.connection[0].credentialId ? response.data.connection[0].credentialId : response.data.connection[0].issuanceId,
                        };
                        // Compressing QR Code.
                        const compress_credential_qr_response = await axios.get(`${process.env.REACT_APP_API_URL}/api/compress_credential_qr`,
                            {
                                params: { data: JSON.stringify(qrObj) },
                                headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                            }
                        );
                        qrCodeObj = {
                            d: compress_credential_qr_response.data.compressed,
                            type: "cv",
                            v: 3,
                            i: "zada"
                        }
                        break;
                    default:
                        console.log('No case matched!')
                }

            } else {
                // Handling v1 QR
                qrCodeObj = {
                    data: response.data.connection[0].encodedData,
                    signature: response.data.connection[0].signature,
                    tenantId: tenantId,
                    keyVersion: response.data.connection[0].keyVersion,
                    type: "cred_ver",
                    issuer: "zada"
                }
            }

            let qrCodeObjJson = JSON.stringify(qrCodeObj);

            setQrCodeImg(qrCodeObjJson);
            setIsQRCodeLoading(false)
        } catch (e) {
            console.log({ e })
            setIsQRCodeLoading(false)
        }


    }

    //New View Work here Start
    const validate = async (rowIndex, cell) => {
        // console.log("rowIndex", rowIndex)
        // console.log("cell", cell)
        try {
            let verification = filteredList[rowIndex];
            // console.log("Verification Data: ", verification)

            setIsValidateLoading(true)
            setIsValidateModalOpen(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search_issuance_log_Id`,
                {
                    params: { id: verification._id },
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                }
            );

            // console.log("Res Data: ", response.data)
            setValidateVerificationData(response.data.connection)
            let proof = response.data.connection[0].passData;
            // console.log("Proof: ", proof)
            setCredential(proof)
            // for (let key in proof) {
            //     let value = proof[key];
            //      console.log("value.attributes123:", value.attributes)
            //      console.log("typeof value === 'object'", typeof value === 'object')
            //     if (typeof value === 'object' && value.attributes) {

            //         setCredential(value.attributes)
            //         break
            //     }
            // }
            setIsValidateLoading(false)
        } catch (e) {
            console.log({ e })
            setIsValidateLoading(false)
        }
    }


    /// New View Work Here Ends

    const verify = (rowIndex, cell) => {
        let connection = connectionsList[rowIndex];
        props.history.push({
            pathname: "/admin/verifications", state: { connection: { ...connection, action: "verify" } }
        })
    }
    const getUserById = async (userId) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user_information`,
                {
                    params: { userId: userId },
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                }
            );

            if (response.data.status === 200) {
                return response.data.user;
            }

            return false;

        } catch (e) {
            console.log({ e })
            return false;
        }

    }

    const loadSearchConnectionListByPhone = (phone) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_issuance_log_connection?phone=' + phone;
        setFilterKeywords({ phone });
        getFilterData(CONN_API_PATH);
    }

    const loadInitialConnectionList = (fromDate, endDate) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_issuance_log_connection?fromDate=' + fromDate + '&toDate=' + endDate;
        setFilterKeywords({ fromDate, endDate });
        getFilterData(CONN_API_PATH);
    }
    const loadSearchConnectionListByName = (name) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_issuance_log_connection?name=' + name;
        setFilterKeywords({ name });
        getFilterData(CONN_API_PATH);
    }

    const getFilterData = (CONN_API_PATH) => {
        try {
            let token = Auth.getToken();
            if (token === undefined) {
                signout();
            } else {
                setIsLoading(true);

                if (!isPaginationApplied) {
                    pageNo = 1;
                    filteredList = [];
                    setTableData([]);
                }

                return fetch(`${CONN_API_PATH}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }).then(res => res.json())
                    .then(json => {
                        if (json.status === 200) {
                            setTotalRecords(json.totalRecords);
                            let connectionArr = json.connections;
                            connectionsList = [];

                            connectionArr.forEach(async function (connection) {
                                let convertedDate = parse_date_time(connection.createdAt)
                                let name = connection.passData.name ? connection.passData.name : connection.passData['Name'] ? connection.passData['Name'] : connection.passData['Full Name'] ? connection.passData['Full Name'] : connection.passData['full_name'];

                                if (!name && connection.passData['User Id']) {
                                    if (!name && connection.passData['User Id'] && connection?.user.length > 0) {
                                        name = connection.user[0].name
                                    }
                                }

                                let data = {
                                    _id: connection._id,
                                    name: name,
                                    connectionId: connection.connectionId,
                                    phone: connection.phone,
                                    issuedDate: convertedDate,
                                    issuedUser: connection.issuedUser ? connection.issuedUser?.name : connection.issuedUser?.username ? connection.issuedUser?.username : "-",
                                    issuedBy: connection.issuedBy ? connection.issuedBy : '-'
                                }
                                connectionsList.push(data);

                            });

                            filteredList = [...filteredList, ...connectionsList]
                            console.log("filteredList", filteredList.length, filteredList)
                            setTableData(filteredList);

                        } else {
                            connectionsList = [];
                            setTableData(connectionsList);
                        }
                        setIsLoading(false);
                        return connectionsList
                    });
            }
        } catch (error) {
            console.log(error.message);
            setIsLoading(false);
            return undefined;
        }
    }
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                hideHeader: false,
                columns: [
                    {
                        Header: "Name",
                        accessor: "name"
                    },
                    {
                        Header: "Phone Number",
                        accessor: "phone"
                    },
                    {
                        Header: "Issued Date",
                        accessor: "issuedDate"
                    },
                    {
                        Header: "Issued By",
                        accessor: "issuedUser"
                    },
                    {
                        Header: "Issuer Id",
                        accessor: "issuedBy"
                    },
                    {
                        width: 300,
                        Header: "Action",
                        Cell: ({ cell }) => (
                            <>
                                <PermissionsGate hasPermission={[PERMISSIONS.issue_single]}>
                                    <Button color="default" size="sm" onClick={() => generateQRCode(cell.row.index, cell)}>
                                        Get QR Code
                                    </Button >
                                </PermissionsGate>

                                <PermissionsGate hasPermission={[PERMISSIONS.verify]}>
                                    <Button color="primary" size="sm" onClick={() => validate(cell.row.index, cell)}>
                                        Show Data
                                    </Button >
                                </PermissionsGate>

                            </>
                        )
                    }

                ]
            }
        ],
        []
    );
    const downloadQR = () => {
        const canvas = document.getElementById("12345");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${userConnectionId}.jpg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    var timeFormats = [
        "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
        "MM-DD-YYYY[T]HH:mm:ss.SSSZ",
        "DD-MM-YYYY[T]HH:mm:ss.SSSZ",
        // without [z]
        "YYYY-MM-DD[T]HH:mm:ss",
        "MM-DD-YYYY[T]HH:mm:ss",
        "DD-MM-YYYY[T]HH:mm:ss",
        // with forward slash
        "YYYY/MM/DD HH:mm:ss",
        "MM/DD/YYYY HH:mm:ss",
        "DD/MM/YYYY HH:mm:ss",
        // with hyphen
        "YYYY-MM-DD HH:mm:ss",
        "MM-DD-YYYY HH:mm:ss",
        "DD-MM-YYYY HH:mm:ss",
    ];

    var dateFormats = [
        // with forward slash
        "YYYY/MM/DD",
        "MM/DD/YYYY",
        "DD/MM/YYYY",
        // with hyphen
        "YYYY-MM-DD",
        "MM-DD-YYYY",
        "DD-MM-YYYY",
    ];

    const is_date_time = (val) => {
        if (!moment(val, timeFormats, true).isValid()) return false

        return true
    }

    const is_date = (val) => {
        if (!moment(val, dateFormats, true).isValid()) return false

        return true
    }

    const get_local_issue_time = (val) => {
        return moment
            .utc(val, timeFormats)
            .local()
            .format('YYYY-MM-DD HH:mm (G[M]T Z)')
            .toString();
    };

    const parse_date_time = (val) => {
        if (is_date(val)) {
            return moment(val).format('YYYY-MM-DD');
        }

        if (is_date_time(val)) {
            return get_local_issue_time(val);
        }

        return val;
    }

    const loadMore = () => {
        pageNo++;
        getData();
    }

    const getData = () => {

        isPaginationApplied = true;

        filterKeywords.hasOwnProperty("phone") && loadSearchConnectionListByPhone(filterKeywords.phone);
        filterKeywords.hasOwnProperty("fromDate") && loadInitialConnectionList(filterKeywords.fromDate, filterKeywords.endDate);
        filterKeywords.hasOwnProperty("name") && loadSearchConnectionListByName(filterKeywords.name);

    }

    return (
        <>
            <Header nameSearch={loadSearchConnectionListByName} filterSearch={loadInitialConnectionList} deepSearch={loadSearchConnectionListByPhone} />
            {/* New View Work */}
            <Modal
                toggle={toggleValidateModal}
                isOpen={isValidateModalOpen}
                backdrop="static"
            >
                <ModalHeader toggle={toggleValidateModal}>
                    <h2>{issuanceLogs_screen.modal_validate_heading}</h2>
                </ModalHeader>

                <ModalBody>
                    {isValidateLoading ?
                        <div className="text-center"><Spinner>{issuanceLogs_screen.loading}</Spinner></div>
                        :
                        <div>
                            <h3>{issuanceLogs_screen.modal_validate_content_heading}</h3>
                            <p><span className="font-weight-bold">{issuanceLogs_screen.modal_validate_content_phone}</span>: <span>{validateVerificationData[0]?.phone}</span></p>
                            {/* <p><span className="font-weight-bold">Valid</span>: <span>{String(validateVerificationData.isValid)}</span></p> */}
                            <p><span className="font-weight-bold">{issuanceLogs_screen.modal_validate_content_issue_date}</span>: <span>{moment(validateVerificationData[0]?.createdAt).format("YYYY-MM-DD")}</span></p>
                            <div>
                                <h3>{issuanceLogs_screen.modal_validate_content_proof}</h3>
                                {Object.entries(credential).map(([key, value]) => {
                                    try {
                                        value = parse_date_time(value)
                                    } catch (error) {
                                        console.log("Error: ", error)
                                    }
                                    return <div><span className="font-weight-bold">{key}</span>:{" "}<span>{value}</span></div>
                                })}
                            </div>
                        </div>}
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={() => setIsValidateModalOpen(false)}>
                        {issuanceLogs_screen.modal_button_close}
                    </Button>
                </ModalFooter>
            </Modal>
            {/* Second Modal */}

            <Modal
                toggle={toggleQRCodeModal}
                isOpen={isQRCodeModalOpen}
                backdrop="static"
            >
                <ModalHeader toggle={toggleQRCodeModal}>
                    <h2>{issuanceLogs_screen.modal_QR_heading}</h2>
                </ModalHeader>

                <ModalBody>
                    {isQRCodeLoading ?
                        <div className="text-center"><Spinner>{issuanceLogs_screen.modal_loading}</Spinner></div>
                        :
                        <>
                            <div style={{ padding: "10px" }}><center><h3>{issuanceLogs_screen.modal_QR_content}</h3>
                            </center></div>
                            <div style={{ textAlign: "center" }}>
                                <QRCode
                                    id={"12345"}
                                    size={393}
                                    level={"M"}
                                    value={qrCodeImg}
                                    renderAs={'canvas'}
                                    includeMargin={true}

                                />


                                {/* <img src={qrCodeImg}/> */}
                                {/* <h3>User1 Information:</h3> */}
                                {/* <p><span className="font-weight-bold">Phone Number</span>: <span>{validateVerificationData[0]?.phone}</span></p> */}
                                {/* <p><span className="font-weight-bold">Valid</span>: <span>{String(validateVerificationData.isValid)}</span></p> */}
                                {/* <p><span className="font-weight-bold">Issued Date</span>: <span>{(new Date(validateVerificationData[0]?.createdAt)).toLocaleDateString()}</span></p> */}
                                {/* <div>
                                <h3>Proof Data:</h3>

                            </div> */}
                            </div>
                        </>}
                    <br />
                    <center> <Button color="default" size="md" onClick={downloadQR}>
                        {issuanceLogs_screen.modal_button_download}
                    </Button></center>
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={() => setIsQRCodeModalOpen(false)}>
                        {issuanceLogs_screen.modal_button_close}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* New Work here ends */}
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">{issuanceLogs_screen.sub_heading}<Badge
                                    style={{ marginLeft: '0.5rem', backgroundColor: "#9065E0", color: "white" }}
                                    pill
                                >{filteredList.length}</Badge></h3>
                            </CardHeader>
                            {!!tableData?.length ?
                                (<ReactTable columns={columns} data={tableData} />) : (
                                    <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
                                        <Table className="align-items-center table-flush">

                                            <thead className="thead-light">
                                                <tr>
                                                    {/* <th scope="col">Name</th>
                                                    <th scope="col">E-mail</th>
                                                    <th scope="col">Phone Number</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th> */}

                                                    <th scope="col">{issuanceLogs_screen.column_name}</th>
                                                    <th scope="col">{issuanceLogs_screen.column_phone}</th>
                                                    <th scope="col">{issuanceLogs_screen.column_issue_date}</th>
                                                    <th scope="col">{issuanceLogs_screen.column_issue_by}</th>
                                                    <th scope="col">{issuanceLogs_screen.column_issuer_id}</th>
                                                    <th scope="col">{issuanceLogs_screen.column_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        {isLoading ? (
                                                            <div> {issuanceLogs_screen.loading} </div>) : <div>{issuanceLogs_screen.no_data}</div>}

                                                    </th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                )
                            }
                            {
                                tableData.length > 0 && tableData.length < totalRecords && <div className="text-center my-4">
                                    <button disabled={isLoading} className="btn btn-primary" onClick={loadMore}> {issuanceLogs_screen.load_more} {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
                                </div>
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default IssuanceLogs;