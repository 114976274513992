import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react"
import axios from "axios";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Auth from './helpers/Auth';
import { ConfigContext } from "Context.js";
import routes from './routes';
import PermissionsGate from "components/Permissions/PermissionsGate";
import { ToastContainer, toast } from 'react-toastify';


function App() {

    var authSubscriber;
    const [config, setConfig] = useState({})
    const [loggedIn, setLoggedIn] = useState(false)
    const [displayMessage, setDisplayMessage] = useState(true);

    useEffect(() => {
        console.log("run app")
        const isEnterprise = process.env.REACT_APP_PORTAL_TYPE === 'saas' ? false : true;
        const _getConfig = async () => {

            // if tenant logged in then get config from local storage
            let isAuthenticated = Auth.getAuth();
            
            // Return if enterprise and not logged in;
            if(!isEnterprise && !isAuthenticated) return;
            
            const configUrl = isAuthenticated ? localStorage.getItem('configUrl') : process.env.REACT_APP_CONFIG_JSON_FILE

            const result = await axios.get(configUrl, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            });
            setConfig(result.data.client)
        }

        _getConfig()

    }, [setConfig, loggedIn])

    const authChecker = useCallback(() => {
        authSubscriber = setInterval(() => {

            // Authentication check not required for login.
            if(!window.location.pathname.includes("/auth/login")) {
                let authStatus = Auth.checkAuth();

                // Session about to expire
                if (authStatus.displayMessage && displayMessage) {
                    toast.info('Your session is about to expire! Click here to restart your session.', {
                        position: "top-center",
                        autoClose: 120000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 1,
                        theme: "light",
                        limit: 1,
                        onClick: () => Auth.refreshToken(),
                    });
                    setDisplayMessage(false)
                }
    
                // Logout user
                if (authStatus.logOut) {
                    Auth.signout();
                    window.location.reload();
                }
            }

        }, 1000)
    }, [displayMessage])

    useEffect(() => {
        authChecker();

        return () => clearInterval(authSubscriber);

    }, [displayMessage])

    const getAvailableRoutes = () => {
        let filteredRoutes = routes.filter(e => {
            if (e?.permission) {
                if (PermissionsGate({ isFunction: true, hasPermission: e?.permission })) return e;
            }
            return null;
        })
        return '/admin' + filteredRoutes[0].path
    }

    let isAuthenticated = Auth.getAuth();
    const isEnterprise = process.env.REACT_APP_PORTAL_TYPE === 'saas' ? false : true;
    let path = "/auth/login/";
    if(!isEnterprise) {
        let tenantId = localStorage.getItem('tenantId');
        path = "/auth/login/" + tenantId;
    }
    if (isAuthenticated === undefined) {
        return (
            <div style={{ height:"100vh" }} className="App">
                <ConfigContext.Provider value={{ config, setConfig, setLoggedIn }}>
                    <Router>
                        <Switch>
                            <Route path="/auth/*" render={(props) => <AuthLayout {...props} />} />
                            <Route path="*">
                                <Redirect to={path} />
                            </Route>
                        </Switch>
                    </Router>
                </ConfigContext.Provider>
            </div>
        );
    }
    else {
        return (
            <div className="App">
                <ToastContainer />
                <ConfigContext.Provider value={{ config, setLoggedIn }}>
                    <Router>
                        <Switch>
                            <Route path="/admin/*" render={(props) => <AdminLayout {...props} />} />
                            <Route path="*">
                                <Redirect to={getAvailableRoutes()} />
                            </Route>
                        </Switch>
                    </Router>
                </ConfigContext.Provider>
            </div>
        );
    }
}

export default App;