import React, { useState } from "react";
import { useTable } from "react-table";
import axios from "axios";

import {
    Redirect
} from "react-router-dom";
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Button,
    Media,
    Pagination,
    PaginationItem,
    Table,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from "reactstrap";
import {verificationlist_screen} from "../content";
// core components
import Header from "../components/Headers/ConnectionHeader.js";
import Auth from '../helpers/Auth';
import jwt from "jsonwebtoken";
var moment = require('moment');
const fetch = require('node-fetch');

let verificationsList = [];

const TOTAL_NUMBER_OF_RECORD_PER_PAGE = 20;
var pageNo = 1;
var filteredList = [];

function ReactTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });

    // Render the UI for your table
    return (
        <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
            <table className="align-items-center table-flush table responsive" {...getTableProps()} >
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) =>
                                column.hideHeader === false ? null : (
                                    <th scope="col" {...column.getHeaderProps()}>{column.render("Header")}</th>
                                )
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export const signout = () => {
    Auth.signout();
    window.location.reload();
};

const Tables = (props) => {

    var isPaginationApplied = false;

    const [totalRecords, setTotalRecords] = useState(0);
    const [filterKeywords, setFilterKeywords] = useState({});

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
    const [validateVerificationData, setValidateVerificationData] = useState({})
    const [credential, setCredential] = useState({})
    const toggleValidateModal = () => setIsValidateModalOpen(!isValidateModalOpen);

    const iToken = localStorage.getItem("itoken")
    let decodedToken;
    try {
        decodedToken = jwt.decode(iToken)
    } catch (e) {
        console.log({ e })
    }

    const isIssuer = decodedToken["role"] === "issuer";
    const isVerifier = decodedToken["role"] === "verifier";
    const isAdmin = decodedToken["role"] === "admin";

    React.useEffect(() => {
        let firstLoad = localStorage.getItem('firstLoad');
        if (firstLoad === 'true') {
            let fromDate = moment().format("YYYY-MM-DD")
            let data;
            // let data = loadInitialConnectionList(fromDate, fromDate);
            if (data !== undefined) {
                data.then((d) => {
                    localStorage.setItem('table', JSON.stringify(d));
                    verificationsList = d;
                   // setTableData(verificationsList);
                });
            }
            localStorage.setItem('firstLoad', false);
        } else {
            let t = localStorage.getItem('table');
            verificationsList = JSON.parse(t);
           // setTableData(verificationsList);
        }
    }, []);

    const validate = async (rowIndex, cell) => {

        try {
            let verification = filteredList[rowIndex];
            setIsValidateLoading(true)
            setIsValidateModalOpen(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-verification-details`,
                {
                    params: { verificationId: verification.verificationId },
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                }
            );
            setValidateVerificationData(response.data.verification)
            let proof = response.data.verification.proof;
            for (let key in proof) {
                let value = proof[key];
                // console.log("value.attributes:", value.attributes)
                // console.log("typeof value === 'object'", typeof value === 'object')
                if (typeof value === 'object' && value.attributes) {
                    setCredential(value.attributes)
                    break
                }
            }
            setIsValidateLoading(false)
        } catch (e) {
            console.log({ e })
            setIsValidateLoading(false)
            setValidateVerificationData({message: e.response.data.message});
        }
    }

    const verify = (rowIndex, cell) => {
        let verification = filteredList[rowIndex];
        props.history.push({
            pathname: "/admin/verifications", state: { connection: { phone: verification.phone, connectionId: verification.connectionId, action: "Re-Verify" } }
        })
    }

    const loadSearchConnectionListByPhone = (phone) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_verification?phone=' + phone;
        setFilterKeywords({ phone });
        getFilterData(CONN_API_PATH)
    }

    const loadInitialConnectionList = (fromDate, endDate) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_connection?fromDate=' + fromDate + '&toDate=' + endDate;
        setFilterKeywords({ fromDate, endDate });
        getFilterData(CONN_API_PATH)
    }
    const loadSearchConnectionListByName = (name) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_verification?name=' + name;
        setFilterKeywords({ name });
        getFilterData(CONN_API_PATH)
    }

    const getFilterData = (CONN_API_PATH) => {
        try {
            let token = Auth.getToken();
            if (token === undefined) {
                signout();
            } else {
                setIsLoading(true);
                
                if (!isPaginationApplied) {
                    pageNo = 1;
                    filteredList = [];
                    setTableData([]);
                }

                fetch(`${CONN_API_PATH}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }).then(res => res.json())
                    .then(json => {
                        if (json.status === 200) {
                            setTotalRecords(json.totalRecords);
                            let verificationArr = json.verifications;
                            verificationsList = [];
                            verificationArr.forEach(function (verification) {
                                let data = {
                                    policyName: verification.policyName,
                                    state: verification.state,
                                    verifiedAtUtc: parse_date_time(verification.verifiedAtUtc),
                                    isValid: verification.isValid,
                                    phone: verification.phone,
                                    connectionId: verification.connectionId,
                                    verificationId: verification.verificationId
                                }
                                verificationsList.push(data);
                            });

                            filteredList = [...filteredList, ...verificationsList]
                            setTableData(filteredList);
                        
                        } else {
                            verificationsList = [];
                            setTableData(verificationsList);
                        }
                        setIsLoading(false);
                    });
            }
        } catch (error) {
            console.log(error.message);
            setIsLoading(false);
        }
    }
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                hideHeader: false,
                columns: [
                    {
                        Header: "Policy Name",
                        accessor: "policyName"
                    },

                    {
                        Header: "State",
                        accessor: "state"
                    },

                    {
                        Header: "Verified At",
                        accessor: "verifiedAtUtc"
                    },

                    {
                        Header: "Valid",
                        accessor: "isValid"
                    },

                    {
                        width: 300,
                        Header: "Action",
                        Cell: ({ cell }) => (
                            <>
                                {(isAdmin || isVerifier) && <Button color="default"
                                    size="sm"
                                    onClick={() => validate(cell.row.index, cell)}
                                >
                                    Validate
                                </Button >}
                                {(isAdmin || isVerifier) && <Button color="primary"
                                    size="sm"
                                    onClick={() => verify(cell.row.index, cell)}
                                >
                                    Re-Verify
                                </Button >}
                            </>
                        )
                    }

                ]
            }
        ],
        []
    );


    var timeFormats = [
        "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
        "MM-DD-YYYY[T]HH:mm:ss.SSSZ",
        "DD-MM-YYYY[T]HH:mm:ss.SSSZ",
        // with forward slash
        "YYYY/MM/DD HH:mm:ss",
        "MM/DD/YYYY HH:mm:ss",
        "DD/MM/YYYY HH:mm:ss",
        // with hyphen
        "YYYY-MM-DD HH:mm:ss",
        "MM-DD-YYYY HH:mm:ss",
        "DD-MM-YYYY HH:mm:ss",
    ];

    var dateFormats = [
        // with forward slash
        "YYYY/MM/DD",
        "MM/DD/YYYY",
        "DD/MM/YYYY",
        // with hyphen
        "YYYY-MM-DD",
        "MM-DD-YYYY",
        "DD-MM-YYYY",
    ];

    const is_date_time = (val) => {
        if (!moment(val, timeFormats, true).isValid()) return false

        return true
    }

    const is_date = (val) => {
        if (!moment(val, dateFormats, true).isValid()) return false

        return true
    }

    const get_local_issue_time = (val) => {
        return moment
            .utc(val, timeFormats)
            .local()
            .format('YYYY-MM-DD HH:mm (G[M]T Z)')
            .toString();
    };

    const parse_date_time = (val) => {
        if (is_date(val)) {
            return moment(val).format('YYYY-MM-DD');
        }

        if (is_date_time(val)) {
            return get_local_issue_time(val);
        }

        return val;
    }

    const loadMore = () => {
        pageNo++;
        getData();
    }

    const getData = () => {

        isPaginationApplied = true;

        filterKeywords.hasOwnProperty("phone") && loadSearchConnectionListByPhone(filterKeywords.phone);
        filterKeywords.hasOwnProperty("fromDate") && loadInitialConnectionList(filterKeywords.fromDate, filterKeywords.endDate);
        filterKeywords.hasOwnProperty("name") && loadSearchConnectionListByName(filterKeywords.name);

    }

    return (
        <>
            <Header nameSearch={loadSearchConnectionListByName} deepSearch={loadSearchConnectionListByPhone} />
            <Modal
                toggle={toggleValidateModal}
                isOpen={isValidateModalOpen}
                backdrop="static"
            >
                <ModalHeader toggle={toggleValidateModal}>
                    <h2>{verificationlist_screen.modal_validate_heading}</h2>
                </ModalHeader>

                <ModalBody>
                    {isValidateLoading ?
                        <div className="text-center"><Spinner>{verificationlist_screen.modal_loading}</Spinner></div>
                        :
                        
                        validateVerificationData.message ? 
                        <div>
                            <h3>{verificationlist_screen.modal_validate_content_heading}</h3>
                            <p><span>{validateVerificationData.message}</span></p>
                        </div>
                        :
                        <div>   
                            <h3>{verificationlist_screen.modal_validate_content_heading}</h3>
                            <p><span className="font-weight-bold">{verificationlist_screen.modal_validate_content_policy}</span>: <span>{validateVerificationData.policy?.name}</span></p>
                            {/* <p><span className="font-weight-bold">Valid</span>: <span>{String(validateVerificationData.isValid)}</span></p> */}
                            <p><span className="font-weight-bold">{verificationlist_screen.modal_validate_content_state}</span>: <span>{validateVerificationData.state}</span></p>
                            <div>
                                <h3>{verificationlist_screen.modal_validate_content_proof}</h3>
                                {Object.entries(credential).map(([key, value]) => {
                                    try{
                                    value = parse_date_time(value)
                                    }catch(error){
                                        console.log("Error: ",error)
                                    }
                                    return <div><span className="font-weight-bold">{key}</span>:{" "}<span>{value}</span></div>
                                })}
                            </div>
                        </div>}
                </ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={toggleValidateModal}>
                        {verificationlist_screen.modal_button_close}
                    </Button>
                </ModalFooter>
            </Modal>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">{verificationlist_screen.sub_heading}</h3>
                            </CardHeader>
                            {!!tableData?.length ?
                                (<ReactTable columns={columns} data={tableData} />) : (
                                    <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
                                        <Table className="align-items-center table-flush">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">{verificationlist_screen.column_policyname}</th>
                                                    <th scope="col">{verificationlist_screen.column_state}</th>
                                                    <th scope="col">{verificationlist_screen.column_verified_at}</th>
                                                    <th scope="col">{verificationlist_screen.column_valid}</th>
                                                    <th scope="col">{verificationlist_screen.column_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        {isLoading ? (
                                                            <div> {verificationlist_screen.loading} </div>) : <div>{verificationlist_screen.no_data}</div>}

                                                    </th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                )
                            }
                            {
                                tableData.length > 0 && tableData.length < totalRecords && <div className="text-center my-4">
                                    <button disabled={isLoading} className="btn btn-primary" onClick={loadMore}> {verificationlist_screen.load_more} {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
                                </div>
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;