import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import axios from "axios";

import {
    Redirect
} from "react-router-dom";
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Button,
    Media,
    Pagination,
    PaginationItem,
    Table,
    PaginationLink,
    Progress,
    Container,
    Row,
    UncontrolledTooltip,
    ModalBody,
    Modal,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import { connectionList_table } from "../content";
// core components
import Header from "../components/Headers/ConnectionHeader.js";
import PermissionsGate from "../components/Permissions/PermissionsGate";
import PERMISSIONS from "../components/Permissions/Permissions";
import Auth from '../helpers/Auth';
import jwt from "jsonwebtoken";
var moment = require('moment');
const fetch = require('node-fetch');

let connectionsList = [];

const TOTAL_NUMBER_OF_RECORD_PER_PAGE = 20;
var pageNo = 1;
var filteredList = [];

function ReactTable({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });

    // Render the UI for your table
    return (
        <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
            <table className="align-items-center table-flush table responsive" {...getTableProps()} >
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) =>
                                column.hideHeader === false ? null : (
                                    <th scope="col" {...column.getHeaderProps()}>{column.render("Header")}</th>
                                )
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export const signout = () => {
    Auth.signout();
    window.location.reload();
};

const Tables = (props) => {

    var isPaginationApplied = false;

    const [totalRecords, setTotalRecords] = useState(0);
    const [filterKeywords, setFilterKeywords] = useState({});

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //Accept Policy Statement code here start
    const [isPrivacyStatement, setIsPrivacyStatement] = useState(false);
    const [isPolicyStatementModalOpen, setIsPolicyStatementModalOpen] = useState(false);

    const togglePolicyStatementModal = async () => {

        setIsPolicyStatementModalOpen(false);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_privacy_policy_val`,
            { id: localStorage.getItem('_id') },
            {

                headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
            }
        );

        console.log("Updated Res: ", response)


    }


    //Accept Policy Statement code here End




    const iToken = localStorage.getItem("itoken")
    let decodedToken;
    try {
        decodedToken = jwt.decode(iToken)
        // console.log("docodeT: ", decodedToken)
    } catch (e) {
        console.log({ e })
    }

    const isIssuer = decodedToken["role"] === "issuer";
    const isVerifier = decodedToken["role"] === "verifier";
    const isAdmin = decodedToken["role"] === "admin";
    const isRequestor = decodedToken["role"] === "temp_requester_approver";

    React.useEffect(() => {
        let firstLoad = localStorage.getItem('firstLoad');
        // console.log("FirstLoad: ", firstLoad)
        if (firstLoad === 'true') {
            let fromDate = moment().format("YYYY-MM-DD")
            // let data = loadInitialConnectionList(fromDate, fromDate);
            let data;
            if (data !== undefined) {
                data.then((d) => {
                    localStorage.setItem('table', JSON.stringify(d));
                    connectionsList = d;
                    // setTableData(connectionsList);
                });
            }
            localStorage.setItem('firstLoad', false);
        } else {
            let t = localStorage.getItem('table');
            connectionsList = JSON.parse(t);
            // console.log("cList: ", connectionsList)
            // setTableData(connectionsList);
        }
    }, []);

    const issueCred = async (rowIndex, cell) => {
        // console.log("rowInd", rowIndex)
        let connection = filteredList[rowIndex];
        var userIdInfo = ""
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_userid_from_user`,
            {
                params: { phone: connection.phone },
                headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
            }
        );

        if (response.data.message == 'success') {
            userIdInfo = response.data.connection.userId;
        }
        else {
            userIdInfo = ""
        }

        props.history.push({
            pathname: "/admin/issue-single", state: { connection: { ...connection, action: "issue", userId: userIdInfo } }
        })
    }

    const verify = (rowIndex, cell) => {
        let connection = filteredList[rowIndex];
        props.history.push({
            pathname: "/admin/verifications", state: { connection: { ...connection, action: "verify" } }
        })
    }

    const loadSearchConnectionListByPhone = (phone) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_connection?phone=' + phone;
        setFilterKeywords({ phone });
        getFilterData(CONN_API_PATH)
    }
    const loadInitialConnectionList = (fromDate, endDate) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_connection?fromDate=' + fromDate + '&toDate=' + endDate;
        setFilterKeywords({ fromDate, endDate });
        getFilterData(CONN_API_PATH)
    }
    const loadSearchConnectionListByName = (name) => {
        var CONN_API_PATH = process.env.REACT_APP_API_URL + '/api/filter_connection?name=' + name;
        setFilterKeywords({ name });
        getFilterData(CONN_API_PATH)
    }

    const getFilterData = (CONN_API_PATH) => {
        try {
            let token = Auth.getToken();
            if (token === undefined) {
                signout();
            } else {

                setIsLoading(true);

                if (!isPaginationApplied) {
                    pageNo = 1;
                    filteredList = [];
                    setTableData([]);
                }

                fetch(`${CONN_API_PATH}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }).then(res => res.json())
                    .then(json => {
                        if (json.status === 200) {
                            setTotalRecords(json.totalRecords);
                            let connectionArr = json.connection;
                            connectionsList = [];
                            connectionArr.forEach(function (connection) {
                                let data = { name: connection.name, email: connection.email, phone: connection.phone, status: connection.status, connectionId: connection.connectionId }
                                connectionsList.push(data);
                            });
                            filteredList = [...filteredList, ...connectionsList]
                            setTableData(filteredList);
                        } else {
                            connectionsList = [];
                            setTableData(connectionsList);
                        }
                        setIsLoading(false);
                    });
            }
        } catch (error) {
            console.log(error.message);
            setIsLoading(false);
        }
    }
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                hideHeader: false,
                columns: [
                    {
                        Header: "Name",
                        accessor: "name"
                    },
                    {
                        Header: "E-mail",
                        accessor: "email"
                    },
                    {
                        Header: "Phone",
                        accessor: "phone"
                    },

                    {
                        Header: "Status",
                        accessor: "status"
                    },
                    {
                        width: 300,
                        Header: "Action",
                        Cell: ({ cell }) => (
                            <>
                                <PermissionsGate hasPermission={[PERMISSIONS.issue_single]}>
                                    <Button color="default"
                                        size="sm"
                                        onClick={() => issueCred(cell.row.index, cell)}
                                    >
                                        Issue Credential
                                    </Button >
                                </PermissionsGate>
                                <PermissionsGate hasPermission={[PERMISSIONS.verify]}>
                                    <Button color="primary"
                                        size="sm"
                                        onClick={() => verify(cell.row.index, cell)}
                                    >
                                        Verify
                                    </Button >
                                </PermissionsGate>

                            </>
                        )
                    }

                ]
            }
        ],
        []
    );

    useEffect(async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user_data`,
            {
                params: { id: localStorage.getItem('_id') },
                headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
            }
        );
        // console.log("data agaya: ", response.data.connection)

        if (response.data.connection) {
            if (response.data.connection?.privacyPolicyStatement == undefined) {
                // console.log("Inside Undefine")
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/insert_policy_privacy_attribute`,
                    { id: response.data.connection._id }
                    ,
                    {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                    }
                );
                setIsPolicyStatementModalOpen(true)
                setIsPrivacyStatement(false)


            }
            else if (response.data.connection?.privacyPolicyStatement == false) {
                // console.log("Inside false policy")

                setIsPolicyStatementModalOpen(true)
                setIsPrivacyStatement(response.data.connection?.privacyPolicyStatement)
            }
            else {
                setIsPrivacyStatement(response.data.connection?.privacyPolicyStatement)
                // console.log("Inside else")

            }


        }


    }, []);

    const loadMore = () => {
        pageNo++;
        getData();
    }

    const getData = () => {

        isPaginationApplied = true;

        filterKeywords.hasOwnProperty("phone") && loadSearchConnectionListByPhone(filterKeywords.phone);
        filterKeywords.hasOwnProperty("name") && loadSearchConnectionListByName(filterKeywords.name);
        filterKeywords.hasOwnProperty("fromDate") && loadInitialConnectionList(filterKeywords.fromDate, filterKeywords.endDate);

    }

    return (
        <>
            {/* //policy Privacy Statement Modal code here  */}
            {
                !isPrivacyStatement ?
                    <Modal
                        toggle={togglePolicyStatementModal}
                        isOpen={isPolicyStatementModalOpen}
                        backdrop="static"
                    >
                        <ModalHeader className="priv-statement-modal" toggle={togglePolicyStatementModal}>
                            <div className="priv-statement-title">

                                <h2>Terms and Conditions</h2>

                            </div>

                        </ModalHeader>

                        <ModalBody >
                            <div className="priv-statement">
                                <ul>
                                    <li>I have read and agree to follow the policies and rules for the credentials issued as detailed at<a href=" https://zada.io/docs/ecosystem-policies/"> https://zada.io/docs/ecosystem-policies/</a></li>
                                    <li>I have read and understand the Terms of Service as detailed on <a href="https://zada.io/docs/terms-of-service/">https://zada.io/docs/terms-of-service/</a> and that:</li>

                                    <li>I am responsible for maintaining the security of my account and password. </li>
                                    <li>I am responsible for all activity that occurs under my account. That includes others who have access to my login credentials.</li>
                                    <li>I am responsibility for the authenticity of the data issued in the credentials.</li>

                                </ul>
                            </div>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary" onClick={togglePolicyStatementModal}>
                                Accept
                            </Button>
                        </ModalFooter>
                    </Modal>
                    :
                    ("")
            }

            {/* //policy Privacy Statement Modal code end here  */}




            <Header nameSearch={loadSearchConnectionListByName} filterSearch={loadInitialConnectionList} deepSearch={loadSearchConnectionListByPhone} />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">{connectionList_table.table_heading}</h3>
                            </CardHeader>
                            {!!tableData?.length ?
                                (<ReactTable columns={columns} data={tableData} />) : (
                                    <div style={{ display: 'block', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', borderBottom: '1px solid grey' }}>
                                        <Table className="align-items-center table-flush">

                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">{connectionList_table.column_name}</th>
                                                    <th scope="col">{connectionList_table.column_email}</th>
                                                    <th scope="col">{connectionList_table.column_phone}</th>
                                                    <th scope="col">{connectionList_table.column_status}</th>
                                                    <th scope="col">{connectionList_table.column_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        {isLoading ? (
                                                            <div> {connectionList_table.data_loading} </div>) : <div>{connectionList_table.no_data}</div>}

                                                    </th>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                )
                            }
                            {
                                tableData.length > 0 && tableData.length < totalRecords && <div className="text-center my-4">
                                    <button disabled={isLoading} className="btn btn-primary" onClick={loadMore}> {connectionList_table.load_more} {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
                                </div>
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;