import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Spinner } from "reactstrap"

const ConfirmationModal = ({ headerText, showModal, hideModal, confirmModal, id, message, alertClass, proceedBtnText, loading }) => {
    return (
        <Modal isOpen={showModal} onHide={hideModal} centered>
            <ModalHeader closeButton>
                <h1>{headerText ? headerText : 'Delete Confirmation'}</h1>
            </ModalHeader>
            <ModalBody><div className={`alert ${alertClass}`}>{message}</div></ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={hideModal}>
                    Cancel
                </Button>
                <Button disabled={loading} color="info" onClick={() => confirmModal(id)}>
                    {proceedBtnText ? proceedBtnText : "Delete"} {loading && <Spinner animation="grow" size={'sm'} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal;