import Header from "../components/Headers/ConnectionHeader.js";
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Collapse,
    Form,
    FormGroup
} from "reactstrap";
import { requests_screen } from "../content";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import RequestServices from 'services/Request';
import { Spinner } from "reactstrap"
import PermissionsGate from "../components/Permissions/PermissionsGate";
import PERMISSIONS from "../components/Permissions/Permissions";
import { TbRefresh } from "react-icons/tb";
import { getInputType } from "helpers/utils.js";
import DataServices from "services/Data.js";


let singleCheckIds = [];

const Requests = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [checkedIds, setCheckedIds] = useState([]);
    const [requests, setRequests] = useState([]);
    const [_requests, _setRequests] = useState([]);
    const [prevRequests, setPrevRequests] = useState([]);
    const [modalViewDetailIsOpen, setModalViewDetailIsOpen] = useState(false);
    const [veiwDetailData, setVeiwDetailData] = useState({});
    const [getReqLoading, setGetReqLoading] = useState(false);
    const [saveDetailsloading, setSaveDetailsLoading] = useState(false);
    const [pendingCount, setPendingCount] = useState(0);
    const [approvedCount, setApprovedCount] = useState(0);
    const [rejectCount, setRejectCount] = useState(0);
    const [currentExpandedRows, setCurrentExpandedRows] = useState('');
    const [filter, setFilter] = useState({});
    const [filterCollapse, setFilterCollapse] = useState(true);
    const [editingDisabled, setEditingDisabled] = useState(true);
    const [newValues, setNewValues] = useState({});

    const togglePendingCheckBox = (e) => {

        try {

            let concateIds = [];

            if (modalViewDetailIsOpen) {

                let ids = e.target.checked ? veiwDetailData.batchData?.filter(object => { return e.target.value == object.requestStatus }).map(function (item) { return item._id; }) : [];
                concateIds = [...concateIds, ...ids];

            } else {
                let ids = e.target.checked ? requests?.filter(object => { return e.target.value == object.requestStatus }) : [];

                ids.map((item) => {

                    if (item.isBatch) {
                        let temp = item.batchData?.filter(object => { return e.target.value == object.requestStatus }).map(function (item) { return item._id; });
                        concateIds = [...concateIds, ...temp];
                    }

                    if (!item.isBatch) {
                        concateIds = [...concateIds, item._id];
                    }

                })
            }

            setCheckedIds(concateIds);

        } catch (error) {
            console.log(error);
        }

    }

    const toggleSinglePendingCheckBox = (e) => {
        try {

            let checkedData = JSON.parse(e.target.value);

            if (!checkedData.isBatch && e.target.checked && !checkedIds.includes(checkedData.id)) {
                singleCheckIds = [...singleCheckIds, checkedData.id]
            }

            if (!checkedData.isBatch && !e.target.checked) {
                singleCheckIds = checkedIds?.filter(object => { return object != checkedData.id });
            }

            if (checkedData.isBatch && e.target.checked && !checkedIds.includes(checkedData.id)) {
                let tempData = requests?.filter(object => { return object.requestStatus == 'pending' && object.batchNo == checkedData.batchNo });
                let tempIds = tempData[0].batchData?.filter(object => { return object.requestStatus == 'pending' && object.batchNo == checkedData.batchNo }).map(function (item) { return item._id; });

                singleCheckIds = [...singleCheckIds, ...tempIds];
            }

            if (checkedData.isBatch && !e.target.checked) {
                let tempData = requests?.filter(object => { return object.requestStatus == 'pending' && object.batchNo == checkedData.batchNo });
                let tempIds = tempData[0].batchData?.filter(object => { return object.requestStatus == 'pending' && object.batchNo == checkedData.batchNo }).map(function (item) { return item._id; });

                singleCheckIds = checkedIds?.filter(object => { return !tempIds.includes(object) });
            }

            setCheckedIds(singleCheckIds);

        } catch (error) {
            console.log(error);
        }

    }

    const viewDetail = (data) => {
        setModalViewDetailIsOpen(true);
        setNewValues(data.passData);
        setVeiwDetailData(data)
    }

    const edit = async (data) => {
        let newObj = { ...data.passData };
        Object.keys(newValues).map(key => {
            if(newValues[key].length < 1) {
                newValues[key] = data.passData[key];
            }
            newObj[key] = newValues[key];
        })

        setSaveDetailsLoading(true);
        // Updating data in db.
        await DataServices.updateData(data._id, newObj);
        setSaveDetailsLoading(false);

        data.passData = newObj;
        setVeiwDetailData(data);
        setEditingDisabled(true);
    }

    const approved = async (data) => {
        let ids = [];

        if (!data.isBatch) {
            ids.push(data._id);
        }

        if (data.isBatch) {
            ids = data.batchData?.filter(object => { return object.requestStatus == 'pending' }).map(function (item) { return item._id; });
        }

        const resp = await RequestServices.requestApproved(ids)
        getAllRequests();

    }

    const reject = async (data) => {

        let ids = [];

        if (!data.isBatch) {
            ids.push(data._id);
        }

        if (data.isBatch) {
            ids = data.batchData?.filter(object => { return object.requestStatus == 'pending' }).map(function (item) { return item._id; });
        }

        const resp = await RequestServices.requestReject(ids)
        getAllRequests();

    }

    const approvedAll = async () => {
        const resp = await RequestServices.requestApproved(checkedIds)
        getAllRequests();
    }

    const rejectAll = async () => {
        const resp = await RequestServices.requestReject(checkedIds)
        getAllRequests();
    }

    const getAllRequests = async (filter = {}) => {
        try {
            let temArray = [];

            setCheckedIds([]);
            setGetReqLoading(true);

            const resp = await RequestServices.getRequests(filter);
            console.log("resp", resp)
            _setRequests(resp);

            resp.map((item, index) => {
                if (!item._id) {
                    item.rounds.map((round) => {
                        round.isBatch = false;
                        round.index = index;
                    });
                    temArray = [...temArray, ...item.rounds];
                }

                if (item._id) {
                    item.rounds.map((round) => {
                        round.isBatch = true;
                        round.index = index;
                        round.batchCount = item.count;
                        round.batchData = item.rounds;
                        item.pendingCount = item.rounds?.filter(object => { return object.requestStatus == 'pending' }).length;
                        item.approvedCount = item.rounds?.filter(object => { return object.requestStatus == 'approved' }).length;
                        item.rejectedCount = item.rounds?.filter(object => { return object.requestStatus == 'rejected' }).length;
                        item.pendingData = item.rounds?.filter(object => { return object.requestStatus == 'pending' });
                        item.approvedData = item.rounds?.filter(object => { return object.requestStatus == 'approved' });
                        item.rejectedData = item.rounds?.filter(object => { return object.requestStatus == 'rejected' });
                        round.pendingCount = item.pendingCount;
                        round.approvedCount = item.approvedCount;
                        round.rejectedCount = item.rejectedCount;
                    });

                    if (item.pendingCount > 0) {
                        temArray = [...temArray, item.pendingData[0]];
                    }
                    if (item.approvedCount > 0) {
                        temArray = [...temArray, item.approvedData[0]];
                    }
                    if (item.rejectedCount > 0) {
                        temArray = [...temArray, item.rejectedData[0]];
                    }

                }
            })

            setRequests(temArray);
            setPrevRequests(temArray);
            setGetReqLoading(false);
            requestsCount(temArray);
        } catch (err) {
            console.error({ err })
        }
    }

    const handleEpandRow = (rowId) => {

        if (currentExpandedRows == rowId) {
            setCurrentExpandedRows('')
        } else {
            setCurrentExpandedRows(rowId)
        }

    }

    const applyFilter = () => {
        try {
            let filteredSingleData = prevRequests.filter(object => { return !object.isBatch });
            let filteredBatchData = prevRequests.filter(object => { return object.isBatch });

            if (filter && Object.keys(filter).length > 0) {
                if ('phone' in filter && filter.phone) {
                    filteredSingleData = filteredSingleData.filter(object => { return object.phone.indexOf(filter.phone.replace(/\+/g, '')) > -1 });
                    filteredBatchData = filteredBatchData.filter(function (element) {
                        return element.batchData.some(function (subElement) {
                            return subElement.phone.indexOf(filter.phone.replace(/\+/g, '')) > -1
                        });
                    });
                }

                if ('name' in filter && filter.name) {
                    filteredSingleData = filteredSingleData.filter(object => { return ((object.passData?.name && object.passData?.name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1) || (object.issuedTo.length > 0 && object.issuedTo[0].name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1)) });
                    filteredBatchData = filteredBatchData.filter(function (element) {
                        return element.batchData.some(function (subElement) {
                            return ((subElement.passData?.name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1) || (subElement.issuedTo.length > 0 && subElement.issuedTo[0].name.toLowerCase().indexOf(filter.name.toLowerCase()) > -1))
                        });
                    });
                }

                if ('file_name' in filter && filter.file_name) {
                    filteredSingleData = filteredSingleData.filter(object => { return object.batchNo?.indexOf(filter.file_name.toLowerCase()) > -1 });
                    filteredBatchData = filteredBatchData.filter(function (element) {
                        return element.batchData.some(function (subElement) {
                            return ((subElement.batchNo.toLowerCase().indexOf(filter.file_name.toLowerCase()) > -1))
                        });
                    });
                }

                if (('fromDate' in filter && filter.fromDate) || ('toDate' in filter && filter.toDate)) {

                    let createdFrom = filter.fromDate
                    let createdTo = filter.toDate

                    if (!createdFrom) {
                        createdFrom = createdTo;
                    }
                    if (!createdTo) {
                        createdTo = createdFrom;
                    }

                    filteredSingleData = filteredSingleData.filter(object => { return (moment(moment(object.createdAt).format('YYYY-MM-DD')).isSameOrAfter(createdFrom) && moment(moment(object.createdAt).format('YYYY-MM-DD')).isSameOrBefore(createdTo)) });
                    filteredBatchData = filteredBatchData.filter(function (element) {
                        return element.batchData.some(function (subElement) {
                            return (moment(moment(subElement.createdAt).format('YYYY-MM-DD')).isSameOrAfter(createdFrom) && moment(moment(subElement.createdAt).format('YYYY-MM-DD')).isSameOrBefore(createdTo))
                        });
                    });
                }

                setRequests([...filteredSingleData, ...filteredBatchData]);
                requestsCount([...filteredSingleData, ...filteredBatchData]);

            }
        } catch (error) {
            console.log(error);
        }

    }

    const clearFilter = () => {
        setFilter({});
        setRequests(prevRequests);
        requestsCount(prevRequests);
    }

    const refreshTable = async () => {
        await getAllRequests();
        applyFilter();
    }

    const requestsCount = (reqData) => {
        setPendingCount(reqData?.filter(object => { return object.requestStatus == 'pending' }).length);
        setApprovedCount(reqData?.filter(object => { return object.requestStatus == 'approved' }).length);
        setRejectCount(reqData?.filter(object => { return object.requestStatus == 'rejected' }).length);
    }

    useEffect(() => {
        getAllRequests()
    }, []);

    useEffect(() => {
        setCheckedIds([]);
    }, [veiwDetailData]);

    const toggle = (e) => {
        // setFilterCollapse(!filterCollapse)
    }

    const searchValue = (e) => {
        if (e.keyCode === 13) {
            applyFilter()
        }
    }

    return (
        <div>
            <ToastContainer />
            <Header headerPadding='pb-4 pt-5 pt-md-5' />
            <Container>
                <Card className="bg-secondary shadow mt-4">
                    <CardHeader onClick={toggle} data-type="collapseBanner">
                        <h3 className="mb-0 d-flex align-items-center cursor-pointer">{requests_screen.sub_heading}</h3>
                    </CardHeader>
                    <Collapse isOpen={filterCollapse}>
                        <CardBody>
                            <Row>
                                <Col className="order-xl-1">
                                    <Row>
                                        <div className="col-md-4">
                                            <label htmlFor="fromDate"><b>{requests_screen.label_issued_to_name}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={requests_screen.input_placeholder_name} type="text"
                                                    style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, name: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.name ? filter.name : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="fromDate"><b>{requests_screen.label_phone}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={requests_screen.input_placeholder_phone}
                                                    type="text" style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, phone: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.phone ? filter.phone : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="fromDate"><b>{requests_screen.label_filename}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={requests_screen.input_placeholder_filename}
                                                    type="text" style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, file_name: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.file_name ? filter.file_name : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mt-3 align-items-end">
                                        <div className="col-md-4">
                                            <label htmlFor="fromDate"><b>{requests_screen.label_from}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <Input type="date" style={{ width: 'auto' }} onChange={(e) => setFilter(filter => ({ ...filter, fromDate: e.target.value }))} value={filter.fromDate ? filter.fromDate : ''} />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="fromDate"><b>{requests_screen.label_to}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <Input type="date" style={{ width: 'auto' }} onChange={(e) => setFilter(filter => ({ ...filter, toDate: e.target.value }))} value={filter.toDate ? filter.toDate : ''} />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4" >
                                            <Button color="danger" size="l" onClick={clearFilter}>
                                                {requests_screen.button_clear}
                                            </Button>
                                            <Button color="default" size="l" onClick={applyFilter}>
                                                {requests_screen.button_search}
                                            </Button>
                                        </div>

                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </Container>

            <Container>
                <Row>
                    <Col className="order-xl-1 mt-4">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col ms="8" xs="6">
                                        <h3 className="mb-0 d-flex align-items-center">{requests_screen.cardheader} {getReqLoading ? <Spinner className="ml-3" size="sm" animation="grow" /> : <TbRefresh className="refresh ml-3" onClick={refreshTable} />}</h3>
                                    </Col>
                                    {
                                        checkedIds.length > 0 && activeTab == 1 && !modalViewDetailIsOpen &&
                                        <Col ms="4" xs="6" className="request-action-btn-box text-right">
                                            <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                                                <button
                                                    onClick={approvedAll}
                                                    className='py-1 px-2 border-0 text-white mr-1 approved btn-sm'>
                                                    <i class="fas fa-check"></i> {requests_screen.button_approve_all}
                                                </button>
                                            </PermissionsGate>

                                            <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                                                <button
                                                    onClick={rejectAll}
                                                    className='py-1 px-2 border-0 text-white mr-1 rejected btn-sm'>
                                                    <i class="fas fa-times"></i> {requests_screen.button_reject_all}
                                                </button>
                                            </PermissionsGate>
                                        </Col>
                                    }
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <Nav tabs>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '1' ? 'active' : ''}
                                                onClick={() => { setActiveTab('1'); }}
                                            >
                                                {requests_screen.navlink_1} <span class="badge badge-pill badge-danger">{pendingCount}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '2' ? 'active' : ''}
                                                onClick={() => { setActiveTab('2'); }}
                                            >
                                                {requests_screen.navlink_2} <span class="badge badge-pill badge-danger">{approvedCount}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '3' ? 'active' : ''}
                                                onClick={() => { setActiveTab('3'); }}
                                            >
                                                {requests_screen.navlink_3} <span class="badge badge-pill badge-danger">{rejectCount}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        <input onClick={togglePendingCheckBox} type="checkbox" class="my-checkbox" value="pending" />
                                                                    </th>
                                                                    <th>{requests_screen.table_col_schema}</th>
                                                                    <th>{requests_screen.table_col_phone}</th>
                                                                    <th>{requests_screen.table_col_batch}</th>
                                                                    <th>{requests_screen.table_col_issueTo}</th>
                                                                    <th>{requests_screen.table_col_issueBy}</th>
                                                                    <th>{requests_screen.table_col_created_date}</th>
                                                                    <th>{requests_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    if (item.requestStatus == 'pending') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                <th>
                                                                                    <input onClick={toggleSinglePendingCheckBox} checked={checkedIds.includes(item._id)} type="checkbox" class="my-checkbox" value={JSON.stringify({ isBatch: item.isBatch, id: item._id, batchNo: item.batchNo })} />
                                                                                </th>
                                                                                <th>{item.schema[0]?.schemaName}{item.isBatch && <span style={{ color: "red" }}> {` *(${item.pendingCount})`}</span>}</th>
                                                                                <td>{item.isBatch ? '-' : item.phone}</td>
                                                                                <td>{item?.batchNo ? item?.batchNo : "-"}</td>
                                                                                <td>{item.isBatch ? '-' : item.issuedTo[0]?.name ? item.issuedTo[0]?.name : "-"}</td>
                                                                                <td>{item?.issuedByName}</td>
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {requests_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                                                                                        <button
                                                                                            onClick={() => { approved(item) }}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-check"></i> {requests_screen.button_approve}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                                                                                        <button
                                                                                            onClick={() => { reject(item) }}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 rejected'>
                                                                                            <i class="fas fa-times"></i> {requests_screen.button_reject}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {pendingCount == 0 && <div className="text-center mt-3">
                                                            <b>{requests_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{requests_screen.table_col_schema}</th>
                                                                    <th>{requests_screen.table_col_phone}</th>
                                                                    <th>{requests_screen.table_col_batch}</th>
                                                                    <th>{requests_screen.table_col_issueTo}</th>
                                                                    <th>{requests_screen.table_col_issueBy}</th>
                                                                    <th>{requests_screen.table_col_created_date}</th>
                                                                    <th>{requests_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    if (item.requestStatus == 'approved') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                <th>{item.schema[0]?.schemaName}{item.isBatch && <span style={{ color: "red" }}> {` *(${item.approvedCount})`}</span>}</th>
                                                                                <td>{item.isBatch ? '-' : item.phone}</td>
                                                                                <td>{item?.batchNo ? item?.batchNo : "-"}</td>
                                                                                <td>{item.issuedTo[0]?.name ? item.issuedTo[0]?.name : "-"}</td>
                                                                                <td>{item?.issuedByName}</td>
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {requests_screen.button_view_detail}
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {approvedCount == 0 && <div className="text-center mt-3">
                                                            <b>{requests_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{requests_screen.table_col_schema}</th>
                                                                    <th>{requests_screen.table_col_phone}</th>
                                                                    <th>{requests_screen.table_col_batch}</th>
                                                                    <th>{requests_screen.table_col_issueTo}</th>
                                                                    <th>{requests_screen.table_col_issueBy}</th>
                                                                    <th>{requests_screen.table_col_created_date}</th>
                                                                    <th>{requests_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    if (item.requestStatus == 'rejected') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                <th>{item.schema[0]?.schemaName}{item.isBatch && <span style={{ color: "red" }}> {` *(${item.rejectedCount})`}</span>}</th>
                                                                                <td>{item.isBatch ? '-' : item.phone}</td>
                                                                                <td>{item?.batchNo ? item?.batchNo : "-"}</td>
                                                                                <td>{item.issuedTo[0]?.name ? item.issuedTo[0]?.name : "-"}</td>
                                                                                <td>{item?.issuedByName}</td>
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {requests_screen.button_view_detail}
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {rejectCount == 0 && <div className="text-center mt-3">
                                                            <b>{requests_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* View Detail Modal */}
            <Modal isOpen={modalViewDetailIsOpen} centered fullscreen size="lg" scrollable>
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{requests_screen.modal_details_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-0 pb-0">
                    <div className="centered-content d-flex justify-content-center">

                        {
                            !veiwDetailData.isBatch && <Table hover responsive className="requests-table">
                                <tr> <th> {requests_screen.modal_details_th_credentialField} </th> <th> {requests_screen.modal_details_th_value} </th></tr>
                                {veiwDetailData.passData && Object.keys(veiwDetailData.passData).map((key, i) => {
                                    return(
                                    <tr> 
                                        <td>{key}</td> 
                                        <td  style={{ width:"40%" }}> 
                                        {editingDisabled ? 
                                            veiwDetailData.passData[key] : 
                                            <Form>
                                                <FormGroup className="mt-0 mb-0 p-0">
                                                    <Input style={{ color: "black" }} onChange={(e) => setNewValues({...newValues, [key]:e.target.value})} type={getInputType(veiwDetailData.passData[key])} name={key.toLowerCase()} id={key} value={newValues[key]} />
                                                </FormGroup>
                                            </Form>
                                        }
                                        </td>
                                    </tr>
                                )}
                                )}
                            </Table>
                        }

                        {
                            veiwDetailData.isBatch && <Table responsive >
                                <thead>
                                    <tr>
                                        {activeTab == 1 && <th>
                                            <input onClick={togglePendingCheckBox} type="checkbox" class="my-checkbox" value="pending" />
                                        </th>}
                                        <th>{requests_screen.table_col_phone}</th>
                                        <th>{requests_screen.table_col_issueTo}</th>
                                        <th>{requests_screen.table_col_issueBy}</th>
                                        <th>{requests_screen.table_col_created_date}</th>
                                        <th>{requests_screen.table_col_actions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _requests[veiwDetailData.index]?.rounds.map((item) =>
                                            <>
                                                {((activeTab == 1 && item.requestStatus == 'pending') || (activeTab == 2 && item.requestStatus == 'approved') || (activeTab == 3 && item.requestStatus == 'rejected')) &&
                                                    <tr key={item._id}>
                                                        {activeTab == 1 && <th>
                                                            <input onClick={toggleSinglePendingCheckBox} checked={checkedIds.includes(item._id)} type="checkbox" class="my-checkbox" value={JSON.stringify({ isBatch: false, id: item._id, batchNo: item.batchNo })} />
                                                        </th>}
                                                        <td>{item.phone}</td>
                                                        <td>{item.issuedTo[0]?.name ? item.issuedTo[0]?.name : "-"}</td>
                                                        <td>{item?.issuedByName}</td>
                                                        <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                        <td>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => { handleEpandRow(item._id) }}>
                                                                {
                                                                    currentExpandedRows == item._id ? 'Hide' : 'Show'
                                                                }
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                }
                                                <>
                                                    {
                                                        currentExpandedRows == item._id && <tr>
                                                            <td colspan="7">
                                                                <div>
                                                                    <h2 style={{ backgroundColor: 'lightgray' }}> {requests_screen.modal_details_table_heading} </h2>
                                                                    <Table hover responsive className="requests-table">
                                                                        <tr> <th> {requests_screen.modal_details_th_credentialField} </th> <th> {requests_screen.modal_details_th_value} </th></tr>
                                                                        {item.passData && Object.keys(item.passData).map((key, i) => (
                                                                            <tr> <td> {key} </td> <td> {item.passData[key]}</td></tr>
                                                                        )
                                                                        )}
                                                                    </Table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </>
                                            </>
                                        )}
                                </tbody>
                            </Table>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>

                    {((veiwDetailData.isBatch && checkedIds.length > 0 || !veiwDetailData.isBatch)) &&

                        <div className="modal-footer-div">
                            {
                                editingDisabled &&
                                <>
                                    <div>
                                        <PermissionsGate hasPermission={[PERMISSIONS.edit_req]}>
                                            {veiwDetailData.requestStatus == 'pending' &&  <Button 
                                                className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow"
                                                onClick={() => {
                                                    setCheckedIds([]);
                                                    setEditingDisabled(false);
                                                }}
                                            >
                                                <i className="fas fa-edit" />
                                            </Button>
                                            }
                                        </PermissionsGate>
                                    </div>
                                    <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                                    {
                                        veiwDetailData.requestStatus == 'pending' && <Button color="primary" onClick={() => {
                                            setCheckedIds([]);
                                            veiwDetailData.isBatch ? approvedAll() : approved(veiwDetailData);
                                            setModalViewDetailIsOpen(false);
                                        }}>
                                            {requests_screen.button_approve}
                                        </Button>
                                    }
                                    </PermissionsGate>
                                
                                    <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                                        {veiwDetailData.requestStatus == 'pending' && <Button onClick={() => {
                                            setCheckedIds([]);
                                            veiwDetailData.isBatch ? rejectAll() : reject(veiwDetailData);
                                            setModalViewDetailIsOpen(false)
                                        }} color="danger">
                                            {requests_screen.button_reject}
                                        </Button>
                                        }
                                    </PermissionsGate>
                                </>
                            }
                        </div>
                    }


                    {
                        <Button 
                            color={editingDisabled ? "secondary" : "primary" }
                            style={{ width: 100 }}
                            onClick={() => { 
                                if(editingDisabled) {
                                    setModalViewDetailIsOpen(false) 
                                } else {
                                    edit(veiwDetailData);
                                }
                            }}>
                                {
                                    saveDetailsloading ? <Spinner className="ml-10" size="sm" animation="grow" /> 
                                    : 
                                    editingDisabled ? requests_screen.button_close : requests_screen.button_save
                                }
                        </Button>
                    }
                    {!editingDisabled &&
                        <Button 
                            color={"danger" }
                            onClick={() => { 
                                setEditingDisabled(true);                                
                        }}>
                            {requests_screen.button_cancel}
                        </Button>
                    }
                </ModalFooter>
            </Modal>

        </div>
    )

}

export default Requests;