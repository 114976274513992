
/*eslint-disable*/
import React, { useContext } from "react";
import { ConfigContext } from "../../Context"

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const { version } = require('../../../package.json');

const Footer = () => {

  const { config } = useContext(ConfigContext);

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()} | {version && version+" |"} 
            <a
              className="font-weight-bold ml-1"
              href={config.footer?.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {config.footer?.text}
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            {config.adminFooterLinks?.map((data) => {
              return (
                <NavItem>
                  <NavLink
                    href={data.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {data.title}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
