import CredHeader from "components/Headers/CredHeader";
import React, { useState, useEffect } from "react"
import axios from "axios";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import ReactModal from "react-modal";
import { ConfigContext } from "../Context"
import { ToastContainer, toast } from "react-toastify";
import { intersperse, isNullOrWhitespace } from "helpers/utils";
import { standaloneVerifications_screen } from "../content";


const Verifications = (props) => {

    const { config } = React.useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(false);

    const [modalAcceptedIsOpen, setModalAcceptedIsOpen] = useState(false);
    const [modalRejectedIsOpen, setModalRejectedIsOpen] = useState(false);
    const [modalPollingIsOpen, setModalPollingIsOpen] = useState(false);

    const [pollingIdState, setPollingIdState] = useState(0);
    const [pollingStartTimeState, setPollingStartTimeState] = useState(0);
    let pollingId = 0;
    let pollingStartTime = 0;

    const [connection, setConnection] = useState({})
    const [credential, setCredential] = useState({})

    const [policyDropDownOpen, setPolicyDropDownOpen] = useState(false);
    const togglePolicyDropDown = () => setPolicyDropDownOpen(prevState => !prevState);
    const handlePolicyDropDownSelect = (e) => {
        setSelectedPolicyType(e);
    }

    const [availablePolicies, setAvailablePolicies] = useState([])
    const [selectedPolicyType, setSelectedPolicyType] = useState({})

    useEffect(() => {
        const _getPolicies = async () => {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/policies`,
                    {
                        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                        params: { subject: localStorage.getItem('subject') }
                    })
                const policies = resp.data.policies;
                setAvailablePolicies(policies);
                if (policies.length > 0) {
                    setSelectedPolicyType(policies[0])
                }
            } catch (err) {
                console.error({ err })
            }
        }
        _getPolicies()
    }, [])

    useEffect(() => {

        const _connectionData = props.location.state?.connection;
        setConnection(_connectionData)
    }, [setConnection])

    function closeModal() {
        setModalAcceptedIsOpen(false);
        setModalRejectedIsOpen(false);
    }

    const validateVerification = async (verificationId) => {
        try {
            let timePassed = (Date.now() - pollingStartTime) / 1000;
            if (timePassed >= 300) {
                //expired
                cancelPolling();
                console.log("polling expired.")
                // toast.error(`Polling expired.`, {
                //     position: "top-center",
                //     autoClose: 8000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
            }

            var response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-verification-details`,
                {
                    params: { verificationId: verificationId },
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
                }
            );
            if (response.data.success) {
                // console.log(response.data.data.result.verification.state)
                if (response.data.verification.state === "Accepted") {
                    // setCredential(response.data.verification.proof[response.data.verification.policy.name].attributes)
                    let proof = response.data.verification.proof;
                    for (let key in proof) {
                        let value = proof[key];
                        // console.log("value.attributes:", value.attributes)
                        // console.log("typeof value === 'object'", typeof value === 'object')
                        if (typeof value === 'object' && value.attributes) {
                            setCredential(value.attributes)
                            break
                        }
                    }
                    setModalAcceptedIsOpen(true);
                    cancelPolling();
                }
            }
        } catch (error) {
            console.error({ error });
        }
    };

    const startPolling = async (verificationId) => {
        let startTime = Date.now();
        let pId = setInterval(validateVerification, 8000, verificationId);
        setPollingIdState(pId)
        setPollingStartTimeState(startTime)
        pollingId = pId;
        pollingStartTime = startTime;
        setModalPollingIsOpen(true)
        // console.log('Polling Starting: ' + pId + " at " + startTime);
    }

    const cancelPolling = async () => {
        let timePassed = Date.now() - pollingStartTimeState;
        // console.log('Polling Ending: ' + pollingIdState + " at " + timePassed / 1000);
        clearInterval(pollingIdState);
        clearInterval(pollingId);
        setModalPollingIsOpen(false);
    };

    const handleFormSubmit = () => {
        console.log('form submit.')

        if (Object.keys(selectedPolicyType).length <= 0) {
            return toast.error(`Kindly select a policy.`, {
                position: "top-left",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (isNullOrWhitespace(connection.connectionId)) {
            return toast.error(`Connection ID is empty.`, {
                position: "top-left",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setModalPollingIsOpen(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/verify`,
            {
                connectionId: connection.connectionId,
                subject: localStorage.getItem('subject'),
                policyId: selectedPolicyType.policyId
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
            }).then((data) => {
                setIsLoading(false);
                startPolling(data.data.result.verificationId);
                // setModalPollingIsOpen(true)
                return toast.success(`Connection Invitation Sent.`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((e) => {
                console.log({ e })
                setIsLoading(false);
                return toast.error(`Server: ${e.response.data.message}`, {
                    position: "top-left",
                    autoClose: 3500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }

    const PolicyFields = ({ policyAttributes }) => {
        const attributes = policyAttributes?.map((data) => {
            return <span key={data.title}>{data.title}</span>
        });
        return (
            <div className="my-2">
                {intersperse(attributes ?? [], ", ")}
                <span>.</span>
            </div>
        )
    }

    return (
        <div>
            <ToastContainer />
            <ReactModal
                shouldCloseOnOverlayClick={false}
                backdrop="static"
                isOpen={modalPollingIsOpen}
                // onRequestClose={closeModal}
                contentLabel="My dialog"
                className="qrmodal"
                overlayClassName="qroverlay"
            >
                <p>{standaloneVerifications_screen.modal_polling_heading}</p>
                <br />
                <div className="centered-content d-flex justify-content-center">
                    <div id="spinner" className="spinner-border centered-content" role="status">
                        <span className="sr-only centered-content">{standaloneVerifications_screen.modal_polling_message}</span>
                    </div>
                </div>
                <br />

                <div className="text-center">
                    <Button
                        color="primary"
                        onClick={() => {
                            setModalPollingIsOpen(false)
                            cancelPolling()
                        }}
                    >
                        {standaloneVerifications_screen.modal_polling_button_cancel}
                    </Button>
                </div>
            </ReactModal>

            <ReactModal
                shouldCloseOnOverlayClick={false}
                backdrop="static"
                isOpen={modalAcceptedIsOpen}
                contentLabel="My dialog"
                className="qrmodal"
                overlayClassName="qroverlay">

                <div className="centered-content d-flex justify-content-center">
                    <h4>{standaloneVerifications_screen.modal_accepted_heading}</h4>
                </div>
                <br />
                <div className="centered-content d-flex justify-content-center">
                    <p>{standaloneVerifications_screen.modal_accepted_message}</p>
                </div>
                <div className="centered-content d-flex justify-content-center">
                    <img
                        alt="..."
                        height="64px"
                        src={
                            require("../assets/img/theme/check.png")
                                .default
                        }
                    />
                </div>
                <div>
                    {Object.entries(credential).map(([key, value]) => {
                        return <div><span>{key}</span>:{" "}<span>{value}</span></div>
                    })}
                </div>
                <br />
                <Button
                    color="success"
                    style={{ width: "100%" }}
                    onClick={closeModal}
                >
                    {standaloneVerifications_screen.modal_accepted_button_close}
                </Button>
            </ReactModal>


            <ReactModal
                shouldCloseOnOverlayClick={false}
                backdrop="static"
                isOpen={modalRejectedIsOpen}
                contentLabel="My dialog"
                className="qrmodal"
                overlayClassName="qroverlay">

                <div className="centered-content d-flex justify-content-center">
                    <h4>{standaloneVerifications_screen.modal_rejected_heading}</h4>
                </div>
                <br />
                <div className="centered-content d-flex justify-content-center">
                    <p>{standaloneVerifications_screen.modal_rejected_message}</p>
                </div>
                <div className="centered-content d-flex justify-content-center">
                    <img
                        alt="..."
                        height="96px"
                        src={
                            require("../assets/img/theme/cross.png")
                                .default
                        }
                    />
                </div>
                <br />
                <Button

                    style={{ width: "100%", backgroundColor: "#c82606", color: "#ffffff" }}
                    onClick={closeModal}
                >
                    {standaloneVerifications_screen.modal_rejected_button_continue}
                </Button>
            </ReactModal>

            <CredHeader heading={"Verifications"} />

            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{standaloneVerifications_screen.sub_heading}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {
                                    connection ? <div className="">

                                        <div style={{ marginTop: 20 }}>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-policy-type"
                                            >
                                                <h2>{standaloneVerifications_screen.label_policy_type}</h2>
                                            </label>
                                            <div id="input-policy-type">
                                                <Dropdown disabled={isLoading} isOpen={policyDropDownOpen} toggle={togglePolicyDropDown}>
                                                    <DropdownToggle color="white" caret className="dropdown">
                                                        {selectedPolicyType?.policyName}
                                                    </DropdownToggle>
                                                    <DropdownMenu className="menu-dropdown">
                                                        {availablePolicies?.map((data) => {
                                                            return <DropdownItem onClick={() => handlePolicyDropDownSelect(data)}>{data.policyName}</DropdownItem>
                                                        })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            <div className="mt-3">
                                                <h3 className="font-weight-bold">{standaloneVerifications_screen.heading_policy_attributes}</h3>
                                                <PolicyFields policyAttributes={selectedPolicyType?.policyAttributes} />
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <h3>{standaloneVerifications_screen.heading_connection_data}</h3>
                                            {
                                                Object.entries(connection).map(([key, value]) => {
                                                    // return <div>{key}: {value}</div>
                                                    return (
                                                        <div className="mb-1">
                                                            <Label htmlFor={key} style={{ textTransform: "capitalize" }}>{key}</Label>
                                                            <Input id={key} type="text" disabled value={value} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        <Button onClick={() => handleFormSubmit()} className="btn btn-info btn-lg" type="button">
                                            {standaloneVerifications_screen.button_send_verification}
                                        </Button>

                                    </div> : <div>{standaloneVerifications_screen.no_connection}</div>
                                }
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Verifications;