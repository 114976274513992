/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export function intersperse(arr, sep) {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce(function (xs, x, i) {
        return xs.concat([sep, x]);
    }, [arr[0]]);
}

export function isNullOrWhitespace(input) {
    return !input || !input.trim();
}

export function generateRandomPassword() {
    var randomPasswd = Math.random().toString(36).slice(-8);

    return randomPasswd;
}

export function copyToClipboard(text) {
    return navigator.clipboard.writeText(text);
}

export function getInputType(text) {
    return 'text'
}