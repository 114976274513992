
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { ConfigContext } from "../Context"
import jwt from "jsonwebtoken";
import PermissionsGate from "components/Permissions/PermissionsGate";

import routes from "routes.js";

const Admin = (props) => {

  const { config } = React.useContext(ConfigContext);

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    const iToken = localStorage.getItem("itoken")
    let decodedToken;
    try {
      decodedToken = jwt.decode(iToken)
    } catch (e) {
      console.log({ e })
    }
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {

        // if (prop.roles.includes(decodedToken["role"])) {
        if (PermissionsGate({ isFunction: true, hasPermission: prop.permission })) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    const currentRoute = routes.filter((data) => data.layout + data.path === path)
    if (currentRoute?.length) {
      return currentRoute[0].name;
    } else {
      return "Brand"
    }
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{ ...config.brand }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/tables" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
