const PERMISSIONS = {
    view_connection: 'can_view_connection',
    issue_single: 'can_issue_single',
    issue_batch: 'can_issue_batch',
    verify: 'can_verify',
    view_connection_req: 'can_view_connection_req',
    view_verification_list: 'can_view_verification_list',
    view_issuance_logs: 'can_view_issuance_logs',
    send_apporval_req: 'can_send_approval_req',
    view_approval_req: 'can_view_approval_req',
    approve_req: 'can_approve_req',
    reject_req: 'can_reject_req',
    edit_req: 'can_edit_req',
    view_users: 'can_view_users',
    add_user: 'can_add_user',
    edit_user: 'can_edit_user',
    delete_user: 'can_delete_user',
    send_zada_auth: 'can_send_zada_auth',
}

export default PERMISSIONS;