import CredHeader from "components/Headers/CredHeader";
import React, { useEffect, useState } from "react"
import axios from "axios";
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, InputGroup, FormText } from "reactstrap";
import { ConfigContext } from "../Context"
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import {connectionRequest_screen} from "../content";
import QRCode from "qrcode.react"

function isNullOrWhitespace(input) {
    return !input || !input.trim();
}

const ConnectionRequests = (props) => {

    const { config } = React.useContext(ConfigContext);

    const [isConnectionInvitationModalOpen, setIsConnectionInvitationModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggleConnectionInvitationModalOpen = () => setIsConnectionInvitationModalOpen(!isConnectionInvitationModalOpen);

    const [phoneNumber, setPhoneNumber] = useState("")
    const [connectionQRCode, setConnectionQRCode] = useState("")
    const handleFormSubmit = (e) => {
        console.log('form submit.')
        e.preventDefault();

        if (isNullOrWhitespace(phoneNumber)) {
            return toast.error(`Phone Number is empty.`, {
                position: "top-left",
                autoClose: 3500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/send_connection_invitation`,
            {
                phoneNumber: phoneNumber,
                subject: localStorage.getItem('subject')
            },
            {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
            }).then((data) => {
                console.log({ data })
                setIsConnectionInvitationModalOpen(false);
                setIsLoading(false);
                setPhoneNumber("")
                return toast.success(`Connection Invitation Sent.`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((e) => {
                console.log({ e })
                setIsLoading(false);
                return toast.error(`Server: ${e.response.data.message}`, {
                    position: "top-left",
                    autoClose: 3500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }

    useEffect(() => {
        
            let metaData = config?.connectionRequestsConfig?.metadata;
            let type = "connection_request";

            let connectionRequestQrCodeObj = {
                type: type,
                metadata: metaData
            }
    
            let connectionRequestQrCodeObjJson = JSON.stringify(connectionRequestQrCodeObj);
            setConnectionQRCode(connectionRequestQrCodeObjJson)
        
    }, [1])

    return (
        <div>
            <ToastContainer />
            <CredHeader heading={"Connection Requests"} />
            <Modal
                toggle={toggleConnectionInvitationModalOpen}
                isOpen={isConnectionInvitationModalOpen}
                backdrop="static"
            >
                <form onSubmit={handleFormSubmit}>
                    <ModalHeader toggle={toggleConnectionInvitationModalOpen}>
                        <h3>{connectionRequest_screen.modal_header}</h3>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="mb-2 me-sm-2 mb-sm-0 col-md-6">
                            <Label
                                className="me-sm-2 font-weight-bold"
                                for={"input-" + "phoneNumber"}
                            >
                                {connectionRequest_screen.modal_label_phone}
                            </Label>
                            <Input
                                id={"input-" + "phoneNumber"}
                                key={"phoneNumber"}
                                name={"phoneNumber"}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                type={"text"}
                                disabled={isLoading}
                            />
                            <FormText>{connectionRequest_screen.modal_input_label}</FormText>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <div className="px-3">
                            <Button color="primary" type="submit">
                                {connectionRequest_screen.button_send}
                            </Button>
                            <Button color="default" onClick={toggleConnectionInvitationModalOpen}>
                                {connectionRequest_screen.button_cancel}
                            </Button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{connectionRequest_screen.sub_heading}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="text-center my-5">
                                    <div style={{ marginTop: "40px" }}>
                                        
                            <QRCode
                            id={"12345"}
                            size={393}
                            level={"M"}
                            value={connectionQRCode}
                            renderAs={'canvas'}
                            />
                        
                                        {/* <img
                                            // src={config.connectionRequestsConfig?.qrCodeUrl}
                                            src={connectionQRCode}
                                            width={300}
                                            className="img-fluid"
                                            alt="qr"
                                        /> */}
                                    </div>

                                    <div className="my-5">
                                        <InputGroup>
                                            <Input disabled type="text" value={config.connectionRequestsConfig?.connectionUrl} />
                                            <Button color="primary" onClick={() => {
                                                console.log("data==>", moment().utc())
                                                toast.success(`Connection URL Copied.`, {
                                                    position: "top-center",
                                                    autoClose: 5000,
                                                    hideProgressBar: true,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                navigator.clipboard.writeText(config.connectionRequestsConfig?.connectionUrl)
                                            }}>{connectionRequest_screen.button_copy}</Button>
                                        </InputGroup>
                                    </div>

                                    <Button onClick={() => setIsConnectionInvitationModalOpen(true)} className="btn btn-info btn-lg" type="button">
                                        {connectionRequest_screen.button_send_invitation}
                                    </Button>
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ConnectionRequests;