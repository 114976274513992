export const searchConnection = "Search Connections"
export const connectionRequests = "Connection Requests"
export const singleCredentials = "Issue Single Credentials"
export const batchCredentials = "Issue Batch Credentials"
export const standaloneVerifications = "Standalone Verifications"
export const verificationList = "Verification List"
export const issuanceLogs = "Issuance Logs"
export const requests = "Requests"
export const userManagement = "User Management"

export const connection_header = {
    label_searchByPhone: "Search by phone:",
    textbox_searchByPhone: "Search by phone#",
    label_searchByName: "Search by Name:",
    textbox_searchByName: "Search by Name",
    button_search: "Search",
    label_From: "From:",
    label_To: "To:",
    button_searchByDate: "Search by Date"
}

export const connectionList_table = {
    table_heading: "Connection List",
    column_name: "Name",
    column_email: "E-mail",
    column_phone: "Phone Number",
    column_status: "Status",
    column_action: "Action",
    data_loading: "Loading...",
    no_data: "No data found",
    load_more: "Load More"
}

export const connectionRequest_screen = {
    sub_heading: "Connection Requests Board",
    button_copy: "Copy",
    button_send_invitation: "Send Connection Invitation",
    modal_header: "Enter phone number and Submit to send Connection Invitation.",
    modal_label_phone: "Phone Number",
    modal_input_label: "e.g. +959765606651",
    button_send: "Send",
    button_cancel: "Cancel"
}

export const issueSingleCredentials_screen = {
    sub_heading: "Single Certificate Issuance Form",
    phone_message1: "Phone Added",
    phone_message2: "Phone Connection Not Found, Add for Auto Issuance",
    textbox_placeholder: "9599XXXXXXXX",
    button_add: "Add",
    button_find: "Find",
    form_notification: "No connection selected. Please search for a connection first and then fill the form.",
    form_sub_heading: "Select Schema Type",
    form_label_phone: "Phone",
    form_label_connectionID: "Connection Id",
    notification_issuing: "Issuing ",
    loading: "Loading...",
    modal_OK_heading: "Certificate Issuance Successful",
    modal_OK_message: "Certificate is successfully issued",
    modal_OK_button_continue: "Continue",
    modal_NOK_heading: "Certificate Issuance Failed",
    modal_NOK_button_continue: "Continue"
}

export const batchIssuance_screen = {
    sub_heading: "Batch Certificate Issuance Form",
    step_heading: "Batch Issuance Upload",
    step_One: "Step 1: Select Schema Type",
    step_Two: "Step 2: Select Time Zone",
    step_Three: "Step 3: Upload CSV",
    modal_NOK_heading: "Certificate Issuance Failed",
    modal_NOK_button_continue: "Continue",
    modal_Error_heading_part1: "CSV with ",
    modal_Error_heading_part2: " errors is saved locally, Kindly correct and re-upload it.",
    modal_Error_message: "Errors in the CSV file:",
    modal_Error_button_continue: "Continue"
}

export const standaloneVerifications_screen = {
    sub_heading: "Verifications Board",
    label_policy_type: "Select Policy Type",
    heading_policy_attributes: "Policy Attributes:",
    heading_connection_data: "Connection Data:",
    button_send_verification: "Send Verification Request",
    no_connection: "No Connection Data.",
    modal_rejected_heading: "Verification Issuance Failed",
    modal_rejected_message: "Verification request is rejected.",
    modal_rejected_button_continue: "Continue",
    modal_accepted_heading: "Verification Status",
    modal_accepted_message: "Verification request is accepted.",
    modal_accepted_button_close: "Close",
    modal_polling_heading: "Verification Request is Sent, Please Accept!",
    modal_polling_message: "Loading...",
    modal_polling_button_cancel: "Cancel"
}

export const verificationlist_screen = {
    sub_heading: "Verifications List",
    column_policyname: "Policy Name",
    column_state: "State",
    column_verified_at: "Verified At",
    column_valid: "Valid",
    column_action: "Action",
    loading: "Loading...",
    no_data: "No data found",
    load_more: "Load More",
    modal_validate_heading: "Validation",
    modal_loading: "Loading...",
    modal_validate_content_heading: "Validation Status:",
    modal_validate_content_policy: "Policy Name",
    modal_validate_content_state: "State",
    modal_validate_content_proof: "Proof Data:",
    modal_button_close: "Close"
}

export const issuanceLogs_screen = {
    sub_heading: "Issuance Logs List",
    column_name: "Name",
    column_phone: "Phone Number",
    column_issue_date: "Issued Date",
    column_issue_by: "Issued By",
    column_issuer_id: "Issuer Id",
    column_action: "Action",
    loading: "Loading...",
    no_data: "No data found",
    load_more: "Load More",
    modal_QR_heading: "QR Code",
    modal_loading: "Loading...",
    modal_QR_content: "This is a digitally verifiable ZADA QR, Scan this code with ZADA Wallet to verify",
    modal_button_download: "Download QR",
    modal_button_close: "Close",
    modal_validate_heading: "Issuance Log Data",
    modal_validate_content_heading: "User Information:",
    modal_validate_content_phone: "Phone Number",
    modal_validate_content_issue_date: "Issued Date",
    modal_validate_content_proof: "Proof Data:",
}

export const requests_screen = {
    sub_heading: "Search",
    label_issued_to_name: "Issued To Name:",
    input_placeholder_name: "Search by Name",
    label_phone: "Phone:",
    input_placeholder_phone: "Search by Phone",
    label_filename: "File Name:",
    input_placeholder_filename: "Search by File Name",
    label_from: "From:",
    label_to: "To:",
    button_clear: "Clear",
    button_search: "Search",
    cardheader: "Requests Board",
    button_approve_all: "APPROVE ALL",
    button_reject_all: "REJECT ALL",
    navlink_1: "Awaiting Approval",
    navlink_2: "Approved",
    navlink_3: "Rejected",
    table_col_schema: "Schema",
    table_col_phone: "Phone",
    table_col_batch: "Batch #",
    table_col_issueTo: "Issued To",
    table_col_issueBy: "Issued By",
    table_col_created_date: "Created Date",
    table_col_actions: "Actions",
    button_view_detail: "VIEW DETAIL",
    button_approve: "APPROVE",
    button_reject: "REJECT",
    button_edit: "EDIT",
    button_save: "SAVE",
    table_no_request: "NO REQUEST AVAILABLE",
    modal_details_heading: "Issuance Detail",
    modal_details_th_credentialField: "Credential Field",
    modal_details_th_value: "Value",
    modal_details_table_heading: "Credentials",
    button_close: "Close",
    button_cancel:"Cancel"
}

export const userManagement_screen = {
    sub_heading: "User Board",
    button_add_new_user: "ADD NEW USER",
    heading_col_username: "Username",
    heading_col_role: "Role",
    heading_col_created_date: "Created Date",
    toast_info_no_connection: "Connection does not exist",
    modal_addUser_heading_1: "Edit User",
    modal_addUser_heading_2: "Add New User",
    modal_form_label_fullName: "Full Name",
    modal_form_label_username: "Username",
    modal_form_label_email: "Email",
    modal_form_input_help: "Only Alphabets, Numbers, Underscore and characters between 4 to 10.",
    modal_form_label_phone: "Phone",
    modal_form_label_role: "Role",
    modal_form_label_select_role: "Select Role",
    modal_form_label_permissions: "Permissions",
    modal_form_label_imageURL: "Image URL",
    modal_form_label_password: "Password",
    modal_form_alert_message: "Password Successfully Copied!",
    modal_form_label_less_password: "Make User Password Less",
    button_cancel: "Cancel",
    button_update: "Update",
    button_add: "Add",
    modal_OK_heading: "ZADA AUTH Credential Issue Successful",
    modal_OK_message: "Credential is successfully issued",
    button_continue: "Continue",
    modal_NOK_heading: "ZADA AUTH Credential Issuance Failed",
    modal_NOK_message: "Unable to Issue Credential",
    confirmationModal_issueAuthCredentials_header: "Issue Credential Confirmation",
    confirmationModal_issueAuthCredentials_message: "Are you sure you want to issue ZADA AUTH credential to this user? ",
    confirmationModal_deleteUser_message: "Are you sure you want to delete this user! Becuase this action cannot be undone."
}

export const shared_info = {
    modal_contactus_heading: "Contact Us",
    modal_contactus_phone_label: "Phone : ",
    modal_contactus_phone_value: "+95(0)9765606651",
    modal_contactus_email_label: "Email : ",
    modal_contactus_email_value: "help@zada.io",
    modal_contactus_chat_label: "Live chat : ",
    modal_contactus_chat_value: "m.me/zadamyanmar",
    button_close: "Close",
    welcome_text: "Welcome!",
    logout_text: "Logout"
}