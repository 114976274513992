import Index from "views/Index.js";
import Credential from "views/Credential.js";
import Upload from "views/Upload";
import Login from "views/auth/Login.js";
import Tables from "./views/Table.js";
import ConnectionRequests from "views/ConnectionRequests.js";
import Verifications from "views/Verifications.js";
import VerificationsList from "views/VerificationsList.js";
import IssuanceLogs from "views/IssuanceLogs.js";
import Users from "views/Users";
import Requests from "views/Requests";
import PERMISSIONS from "components/Permissions/Permissions";
import {
  searchConnection,
  connectionRequests,
  singleCredentials,
  batchCredentials,
  standaloneVerifications,
  verificationList,
  issuanceLogs,
  requests,
  userManagement
} from "content";

var routes = [
  {
    path: "/tables",
    name: searchConnection,
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
    roles: ["admin", "issuer", "verifier"],
    permission: [PERMISSIONS.view_connection]
  },
  {
    path: "/connection-requests",
    name: connectionRequests,
    icon: "ni ni-bullet-list-67 text-dark",
    component: ConnectionRequests,
    layout: "/admin",
    roles: ["admin", "issuer", "verifier"],
    permission: [PERMISSIONS.view_connection_req],
  },
  {
    path: "/issue-single",
    name: singleCredentials,
    icon: "ni ni-credit-card text-green",
    component: Credential,
    layout: "/admin",
    roles: ["admin", "issuer"],
    permission: [PERMISSIONS.issue_single],
  },
  {
    path: "/issue-batch",
    name: batchCredentials,
    icon: "ni ni-credit-card text-blue",
    component: Upload,
    layout: "/admin",
    roles: ["admin", "issuer"],
    permission: [PERMISSIONS.issue_batch],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/login/:id",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/verifications",
    name: standaloneVerifications,
    icon: "ni ni-badge text-dark",
    component: Verifications,
    layout: "/admin",
    roles: ["admin", "verifier"],
    permission: [PERMISSIONS.verify],
  },
  {
    path: "/verifications-list",
    name: verificationList,
    icon: "ni ni-badge text-red",
    component: VerificationsList,
    layout: "/admin",
    roles: ["admin", "verifier"],
    permission: [PERMISSIONS.view_verification_list],
  },
  {
    path: "/issuance-logs",
    name: issuanceLogs,
    icon: "ni ni-bullet-list-67 text-blue",
    component: IssuanceLogs,
    layout: "/admin",
    roles: ["admin", "issuer"],
    permission: [PERMISSIONS.view_issuance_logs],
  },
  {
    path: "/requests",
    name: requests,
    icon: "fa fa-users text-blue",
    component: Requests,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_approval_req],
  },
  {
    path: "/users",
    name: userManagement,
    icon: "ni ni-single-02 text-blue",
    component: Users,
    layout: "/admin",
    roles: ["admin", "user_admin"],
    permission: [PERMISSIONS.view_users],
  },
];

export default routes;
