
/*eslint-disable*/
import React from "react";
import { ConfigContext } from "../../Context"

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const { version } = require('../../../package.json');

const Login = () => {

  const { config } = React.useContext(ConfigContext);

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted text-white">
                © {new Date().getFullYear()}{" "}| {version && version + " |"}
                <a
                  className="font-weight-bold ml-1 text-white"
                  href={config.footer?.link}
                  target="_blank"
                >
                  {config.footer?.text}
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                {config.authFooterLinks?.map((data) => {
                  return (
                    <NavItem>
                      <NavLink
                        href={data.link}
                        target="_blank"
                        className="text-white"
                      >
                        {data.title}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
