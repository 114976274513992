import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {batchIssuance_screen} from "../content";
import axios from "axios";
import moment from "moment";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
// core components
import CredHeader from "../components/Headers/CredHeader.js";
import { intersperse } from "helpers/utils.js";
import Auth from "helpers/Auth";
import PermissionsGate from "components/Permissions/PermissionsGate";
import PERMISSIONS from "components/Permissions/Permissions";

function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const csvRow = (obj, csvHeader) => {
    return csvHeader.map((head) => {
        return obj[head];
    })
}


const SchemaRequiredFields = ({ schemaAttributes }) => {
    const attributes = schemaAttributes && [...schemaAttributes, { identifier: "phone" }].map((data) => {
        return <span>{data.identifier}</span>
    });
    return (
        <div className="my-2">
            {intersperse(attributes ?? [], ", ")}
            <span>.</span>
        </div>
    )
}

const TimeZones = [
    {
        title: "Africa, Abidjan (GMT)",
        tz: 'Africa/Abidjan',
    },
    {
        title: "Asia, Yangon (GMT+6:30)",
        tz: "Asia/Yangon"
    }
]

const UploadForm = (props) => {

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);
    const [errorCount, setErrorCount] = useState(0);
    const [errorFields, setErrorFields] = useState([]);
    const [errorFieldsObj, setErrorFieldsObj] = useState({});
    const [availableSchemas, setAvailableSchemas] = useState([])
    const [selectedSchemaType, setSelectedSchemaType] = useState({})
    const [schemaDropDownOpen, setSchemaDropDownOpen] = useState(false);
    const [timeZoneDropDownOpen, setTimeZoneDropDownOpen] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState({});
    const [attributes, setAttributes] = useState([])
    const toggleSchemaDropDown = () => setSchemaDropDownOpen(prevState => !prevState);
    const toggleTimeZoneDropDown = () => setTimeZoneDropDownOpen(prevState => !prevState);
    const handleTimeZoneDropDownSelect = (e) => setSelectedTimeZone(e);
    const handleSchemaDropDownSelect = (e) => setSelectedSchemaType(e);

    const mainContent = React.useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [modalNotOKIsOpen, setModalNotOKIsOpen] = useState(false);
    const [modalNotOKayErrMsg, setModalNotOKayErrMsg] = useState('')
    const [uploadButtonText, setUploadButtonText] = useState('Upload & Issue');
    const [schemaId, setSchemaId] = useState("")
    const [file, setFile] = useState("");
    let inputFile = useRef(null);

    function closeModal() {
        setModalNotOKIsOpen(false);
    }

    useEffect(async () => {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/schemas`,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
                    params: { subject: localStorage.getItem("subject") }
                })
            // const schemas = resp.data.schemas.map((data) => {
            //     return {
            //         schemaId: data.schemaId,
            //         schemaName: data.schemaName
            //     }
            // })

            // todo: set available schemas for Batch Issuer 
            const schemas = resp.data.schemas;
            setAvailableSchemas(schemas);

            for (var i = 0; i < schemas.length; i++) {
                if (schemas[i]?.disabled == undefined || !schemas[i]?.disabled) {
                    setSelectedSchemaType(schemas[i])
                    break;
                }
            }


            // setSelectedSchemaType(schemas[0])
            const csvHeader = selectedSchemaType && selectedSchemaType.schemaAttributes?.map((data) => {
                return data.identifier
            });
            setAttributes(csvHeader);
        } catch (err) {
            console.error({ err })
        }

        for (var i = 0; i < TimeZones.length; i++) {
            if (TimeZones[i]?.tz == process.env.REACT_APP_ISSUE_TIME_TZ) {
                setSelectedTimeZone(TimeZones[i])
                break;
            }
        }

    }, [])

    useEffect(() => {
        // console.log({ selectedSchemaType })
        // console.log({ availableSchemas })

    }, [selectedSchemaType, availableSchemas])

    const handleFileUpload = async (e) => {
        try {

            let authRole = Auth.getAuth();

            setIsLoading(true);
            setUploadButtonText('Uploading, Please wait...');
            const { files } = e.target;
            if (files && files.length) {
                const filename = files[0].name;

                var parts = filename.split(".");
                const fileType = parts[parts.length - 1];
                // console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

                setFile(files[0]);
                const formData = new FormData()
                // formData.append('date', moment(date).format('YYYYMMDD'))
                formData.append('csvfile', files[0]);
                formData.append('schemaId', selectedSchemaType.schemaId)
                formData.append('timeZone', selectedTimeZone.tz)
                formData.append('tenantId', selectedSchemaType.tenantId) // Should come from jwt of user.
                formData.append('subject', localStorage.getItem('subject'))

                var API_PATH = process.env.REACT_APP_API_URL + '/api/upload';
                const res = await axios({
                    url: API_PATH,
                    data: formData,
                    method: 'POST',
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') }
                });
                // Return non-OK or non-200 response for file with missing headers.
                if (res.data.status == 200) {
                    setIsLoading(false);
                    setUploadButtonText('Upload');
                    setFile('');
                    if (!res.data.included) {
                        // alert(`CSV headers not matched with selected schema.`)
                        return toast.error(`CSV headers not matched with the selected schema.`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    if (res.data.invalidRows.length !== 0) {
                        setErrorCount(res.data.errorCount);
                        setErrorFields(res.data.errorFields);
                        setErrorFieldsObj(res.data.errorFieldsObj);
                        setIsErrorModalOpen(true);
                        const csvHeader = Object.keys(res.data.invalidRows[0]);
                        const csvData = res.data.invalidRows.map((row) => {
                            return csvRow(row, csvHeader);
                        })
                        const csvRows = [
                            csvHeader,
                            ...csvData
                        ]
                        exportToCsv("errors.csv", csvRows)
                        // alert("CSV with errors is saved, Kindly correct and re-upload it.")
                        // toast.error(`CSV with ${res.data.errorCount} errors is saved locally, Kindly correct and re-upload it.`, {
                        //     position: "top-center",
                        //     autoClose: 5000,
                        //     hideProgressBar: true,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });
                    } else {
                        // alert(res.data.message);

                        let msg = (authRole && PermissionsGate({ isFunction: true, hasPermission: [PERMISSIONS.send_apporval_req] }) && !PermissionsGate({ isFunction: true, hasPermission: ['All'] })) ? `Credential Details Send for Approval` : res.data.message

                        toast.success(`${msg}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
                else {
                    setIsLoading(false);
                    setUploadButtonText('Upload');
                    setFile('');
                    setModalNotOKIsOpen(true);
                    setModalNotOKayErrMsg(res.data.message)
                }
            } else {
                setIsLoading(false);
                setUploadButtonText('Upload');
                setFile('');
            }
        } catch (error) {
            setIsLoading(false);
            setUploadButtonText('Upload');
            setFile('');
            setModalNotOKIsOpen(true);
            setModalNotOKayErrMsg(error.message)
        }
    };

    const onButtonClick = () => {
        inputFile.current.value = "";
        inputFile.current.click();
    };

    const ErrorFieldsDetails = ({ errorFieldsObj }) => {
        return Object.keys(errorFieldsObj).map((data) => {
            return (
                <p>
                    <span className="font-weight-bold">{data}:</span>{" "}
                    {intersperse(errorFieldsObj[data], ", ")}<span>.</span>
                </p>
            )
        })
    }

    return (
        <>
            <div>
                {/* <Button
                    color="danger"
                    onClick={toggleErrorModal}
                >
                    Click Me
                </Button> */}
                <Modal
                    shouldCloseOnOverlayClick={false}
                    backdrop="static"
                    isOpen={modalNotOKIsOpen}
                    contentLabel="My dialog"
                    className="qrmodal"
                    overlayClassName="qroverlay">

                    <div className="centered-content d-flex justify-content-center">
                        <h4>{batchIssuance_screen.modal_NOK_heading}</h4>
                    </div>
                    <br />
                    <div className="centered-content d-flex justify-content-center">
                        {/* <p>Unable to Issue Certificate</p> */}
                        <p>{modalNotOKayErrMsg}</p>
                    </div>
                    <div className="centered-content d-flex justify-content-center">
                        <img
                            alt="..."
                            height="96px"
                            src={
                                require("../assets/img/theme/cross.png")
                                    .default
                            }
                        />
                    </div>
                    <br />
                    <Button

                        style={{ width: "100%", backgroundColor: "#c82606", color: "#ffffff" }}
                        onClick={closeModal}
                    >
                        {batchIssuance_screen.modal_NOK_button_continue}
                    </Button>
                </Modal>

                <Modal
                    toggle={toggleErrorModal}
                    isOpen={isErrorModalOpen}
                    backdrop="static"
                >
                    <ModalHeader toggle={toggleErrorModal}>
                        <h3>{batchIssuance_screen.modal_Error_heading_part1} {errorCount} {batchIssuance_screen.modal_Error_heading_part2}</h3>
                    </ModalHeader>
                    <ModalBody>
                        <h3>{batchIssuance_screen.modal_Error_message}</h3>
                        {/* <p>{intersperse(errorFields, ", ")}<span>.</span></p> */}
                        <ErrorFieldsDetails errorFieldsObj={errorFieldsObj} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={toggleErrorModal}>
                            {batchIssuance_screen.modal_Error_button_continue}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
            <ToastContainer />
            <Container>
                <div className="main-content" ref={mainContent} style={{ margin: 30 }}>
                    <h1><b>{batchIssuance_screen.step_heading}</b></h1>
                    <div style={{ marginTop: 20 }}>
                        <label
                            className="form-control-label"
                            htmlFor="input-schema-type"
                        >
                            <h2>{batchIssuance_screen.step_One}</h2>
                        </label>
                        <div id="input-schematype">
                            <Dropdown disabled={!isLoading ? false : true} isOpen={schemaDropDownOpen} toggle={toggleSchemaDropDown}>
                                <DropdownToggle color="white" caret className="dropdown">
                                    {selectedSchemaType?.schemaName}
                                </DropdownToggle>
                                <DropdownMenu className="menu-dropdown">
                                    {availableSchemas?.map((data) => {
                                        if (data?.disabled == undefined || !data?.disabled) {
                                            return <DropdownItem onClick={() => handleSchemaDropDownSelect(data)}>{data.schemaName}</DropdownItem>
                                        }
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <label
                            className="form-control-label mt-3"
                            htmlFor="input-schema-type"
                        >
                            <h2>{batchIssuance_screen.step_Two}</h2>
                        </label>
                        <div id="input-schematype">
                            <Dropdown disabled={!isLoading ? false : true} isOpen={timeZoneDropDownOpen} toggle={toggleTimeZoneDropDown}>
                                <DropdownToggle color="white" caret className="dropdown">
                                    {selectedTimeZone?.title}
                                </DropdownToggle>
                                <DropdownMenu className="menu-dropdown">
                                    {TimeZones?.map((data) => {
                                        if (data?.disabled == undefined || !data?.disabled) {
                                            return <DropdownItem onClick={() => handleTimeZoneDropDownSelect(data)}>{data.title}</DropdownItem>
                                        }
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 20 }}>
                        <h2>{batchIssuance_screen.step_Three}</h2>
                        <SchemaRequiredFields schemaAttributes={selectedSchemaType && selectedSchemaType.schemaAttributes} />
                        <div>
                            <input
                                style={{ display: "none" }}
                                // accept=".zip,.rar"
                                ref={inputFile}
                                onChange={handleFileUpload}
                                type="file"
                            />
                        </div>
                        <Button
                            className="mr-4"
                            color="default"
                            disabled={!isLoading ? false : true}
                            onClick={onButtonClick}
                            size="l"
                        >
                            {uploadButtonText}
                        </Button>
                    </div>
                </div >
            </Container>
        </>
    );
};

const Upload = (props) => {
    return (
        <>
            <CredHeader heading={"Batch Issuance"} />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{batchIssuance_screen.sub_heading}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="pl-lg-4"></div>
                                <UploadForm />
                                <hr className="my-4" />
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Upload;
