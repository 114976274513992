
import React, { useEffect, useState, useContext } from "react";
import Auth from '../../helpers/Auth';
// import Modal from 'react-modal';
import QRCode from "qrcode.react"
// const sessionData = require('../../../db/models/session')
import qrImg from 'assets/img/icons/common/qr-code-.ico'
import io from "socket.io-client";
import { ConfigContext } from "../../Context"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Media,
  Spinner,
} from "reactstrap";

import axios from "axios";
const jwt = require("jsonwebtoken");

const isEnterprise = process.env.REACT_APP_PORTAL_TYPE === 'saas' ? false : true;

const Login = (props) => {

  const { setConfig, setLoggedIn } = useContext(ConfigContext);

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorVisibilityState, setErrorVisibilityState] = useState(false);
  const [loginError, setLoginError] = useState('Invalid username or password, Please try again!');
  const [signInQRCode, setSignInQRCode] = useState("")
  const [checkSpinner, setCheckSpinner] = useState(false)
  const [isQRCodeLoading, setIsQRCodeLoading] = useState(false);

  let userId = "";
  let qrCodeLoginValue = false;

  const Authenticate = () => {
    setErrorVisibilityState(false);
    try {
      var details;

      const tenantId = isEnterprise ? process.env.REACT_APP_ZADA_TENANT_ID : localStorage.getItem('tenantId')

      if (qrCodeLoginValue) {
        details = {
          userId: String(userId),
          qrcodeloginvalue: qrCodeLoginValue,
          tenantId: tenantId
        }
      }
      else {
        details = {
          username: String(username).toLowerCase(),
          password: String(password),
          qrcodeloginvalue: qrCodeLoginValue,
          tenantId: tenantId
        }
      }

      var LOGIN_API_PATH = process.env.REACT_APP_API_URL + '/api/login'

      fetch(LOGIN_API_PATH, {
        method: 'POST',
        body: JSON.stringify(details),
        headers: { 'Content-Type': 'application/json' }
      }).then(res => res.json())
        .then(async json => {
          if (json['success']) {
            //TODO: verify token for more security
            let decodedToken = jwt.decode(json.token);
            let tokenRole = decodedToken['role'];

            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles`, {
              headers: { 'Authorization': 'Bearer ' + json.token }
            })

            let roles = resp.data.roles?.map(e => e.role);

            // === 'issuer' || tokenRole === 'verifier' || tokenRole === 'admin' || tokenRole === 'user_admin'

            if (roles.includes(tokenRole)) {

              localStorage.setItem('name', decodedToken['name']);
              localStorage.setItem('image', decodedToken['image']);
              localStorage.setItem('admin_center', decodedToken['admin_center']);
              localStorage.setItem('tenant', decodedToken['tenant']);
              localStorage.setItem('subject', decodedToken['subject']);
              localStorage.setItem('_id', decodedToken['_id'])
              localStorage.setItem('firstLoad', 'true');
              localStorage.setItem('tenantId', decodedToken['tenantId']);
              localStorage.setItem('configUrl', decodedToken['configUrl']);

              setLoggedIn(true);

              Auth.authenticate(json.token, json.exp);

              // Remove Verification ID
              localStorage.removeItem('verificationId');

              window.location.reload();

            }
            else {
              qrCodeLoginValue = false;
              setLoginError(json['error'])
              setErrorVisibilityState(true);
            }
          } else {
            //login unsuccessful error          
            qrCodeLoginValue = false;
            setLoginError(json['error'])
            setErrorVisibilityState(true);
          }
        });
    } catch (error) {
      //print error
      console.log(error);
      qrCodeLoginValue = false;
      setErrorVisibilityState(true);
    }
  }
  
  const HandleLoginWithQRCode = async () => {

    setIsQRCodeLoading(false)
    setCheckSpinner(true)

    const tenantId = isEnterprise ? process.env.REACT_APP_ZADA_TENANT_ID : localStorage.getItem('tenantId')

    var response = await axios.get(`${process.env.REACT_APP_API_URL}/api/create_connectionless_verification_data?tenantId=${tenantId}`, {});

    let typeVal = response.data.data.type
    let metaDataVal = response.data.data.metadata
    let verificationIdVal = response.data.data.verificationId;

    localStorage.setItem('verificationId', verificationIdVal)

    let signInRequestQrCodeObj = {

      type: typeVal,
      metadata: metaDataVal,
      verificationId: verificationIdVal,

    }

    let signInRequestQrCodeObjJson = JSON.stringify(signInRequestQrCodeObj);
    setSignInQRCode(signInRequestQrCodeObjJson)

    setIsQRCodeLoading(true)

  }

  // Auth login web socket code
  useEffect(() => {

    const socket = io(`${process.env.REACT_APP_API_URL}/api/socket`, {
      transports: ["websocket"]
    });

    socket.on("authenticate", async () => {
      await getSessionData()
    });

    return () => {
      socket.off('authenticate')
    }

  }, [])

  /* Get all tenants */
  useEffect(() => {
    if (!isEnterprise) {
      const init = async () => {
        // Getting tenantId
        const str = window.location.pathname.split("/")
        const tenantId = str[str.length - 1];
        localStorage.setItem('tenantId', tenantId)

        // Getting config url
        const splitStr = process.env.REACT_APP_CONFIG_JSON_FILE.split('/');
        const url = splitStr[2];
        const configUrl = `https://${url}/${tenantId}/config.json`;

        // Getting tenant config.
        const result = await axios.get(configUrl, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        localStorage.setItem('configUrl', configUrl);
        setConfig(result.data.client);
      }
      
      init();
    }
  }, []);

  const getSessionData = async () => {

    let verificationId = localStorage.getItem('verificationId');
    let tenantId = localStorage.getItem('tenantId')

    var res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_session_data`, { params: { verificationId: verificationId, tenantId: tenantId }, });

    if (res.data.data.verified) {

      if (res.data.data.userId != null && res.data.data.userId != undefined && res.data.data.userId != "") {
        try {
          triggerAuth(res.data.data.userId)
        }
        catch (error) {
          return;
        }
      }
    }
  }

  const triggerAuth = (user_id) => {
    userId = user_id;
    qrCodeLoginValue = true;
    Authenticate();
  }

  return (
    <>
      {/* Authentication */}
      <Col lg="6" md="9">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="py-lg-5">
            <div className="text-center text-muted mb-4">
              {/* <small>Or sign in with credentials</small> */}
              {!isQRCodeLoading ? <small>Sign in with QR or Credentials</small> : <small>Scan the QR Code with ZADA Wallet and Sign In <Spinner animation="grow" size="sm" /></small>}
            </div>
            {errorVisibilityState && (
              <div className="text-center text-muted mb-4" >
                {/* <small>Or sign in with credentials</small> */}
                <small style={{ "color": "red" }} >{loginError}</small>
              </div>
            )}

            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="6">
                {/* QR CODE Button */}
                <div className="text-center">


                  {!isQRCodeLoading && <>
                    <Button className="my-4" style={{ width: '100%', height: '50px', padding: '5px', color: '#fff', alignItems: "center", backgroundColor: "#001871" }} type="button" onClick={HandleLoginWithQRCode}>

                      <Media
                        style={{
                          width: '20px',
                          padding: "0px",
                          //  marginTop: '-7px',
                          height: '20px',
                          marginRight: "10px"
                        }}
                        object
                        // src='https://zada.io/wp-content/uploads/2020/12/white_logo_transparent_background-edited-768x512.png'
                        src={qrImg}
                        alt='alt'
                        image
                      />
                      Login with
                      <Media
                        style={{
                          width: '30%',
                          padding: "0px",
                          // marginTop: '-7px',
                          height: '40px',
                          marginLeft: "2px"
                        }}
                        object
                        src='https://zada.io/wp-content/uploads/2020/12/white_logo_transparent_background-edited-768x512.png'
                        //  src={qrImg}
                        alt='alt'
                        image
                      />

                    </Button>
                    <center>
                      {checkSpinner && (<Spinner animation="grow" />)}
                    </center>
                  </>}

                  {isQRCodeLoading &&
                    <div className="auth-login-qr-box">
                      <QRCode
                        id={"12345"}
                        size={393}
                        level={"M"}
                        value={signInQRCode}
                        renderAs={'canvas'}
                      />
                    </div>}
                </div>
                {/* QR CODE  */}
              </Col>
              <Col lg="6" md="6" className="border-left border-primary">
                <Form role="form" onSubmit={(e) => { Authenticate(); e.preventDefault(); }}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-user-run" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        required={true}
                        value={username} onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        required={true}
                        value={password} onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit" >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;