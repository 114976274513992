import React from 'react'

const Select = ({ options, name, id, value, identifier, onChange }) => {
    return (
        <div>
            <select className='form-control' name={name} id={id} onChange={(e) => { onChange(identifier, e.target.value) }}>
                <option selected disabled>Select Option</option>
                {options && options.map((e, key) => {
                    return <option selected={e == value} key={key} value={e}>{e}</option>;
                })}
            </select>
        </div>
    )
}

export default Select;