import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { ConfigContext } from "../Context"
import { ToastContainer, toast } from "react-toastify";
import Select from "components/Select/Select";
import { Spinner } from "reactstrap"
import { isValidPhoneNumber } from 'libphonenumber-js'
import PermissionsGate from "components/Permissions/PermissionsGate";
import PERMISSIONS from "components/Permissions/Permissions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Container,
  Row,
  Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import { issueSingleCredentials_screen } from "../content";
// core components
import CredHeader from "../components/Headers/CredHeader.js";
import axios from "axios";
// var moment = require('moment');
import moment from 'moment-timezone';
import Auth from "helpers/Auth";

Modal.setAppElement("#root");

const inputTypes = {
  string: "text",
  date: "date",
  datetime_local: "datetime-local",
  select: 'select'
}

const Credential = (props) => {

  const { config } = useContext(ConfigContext);

  const [availableSchemas, setAvailableSchemas] = useState([])
  const [selectedSchemaType, setSelectedSchemaType] = useState({})
  const [schemaDropDownOpen, setSchemaDropDownOpen] = useState(false);
  const [modalNotOKayErrMsg, setModalNotOKayErrMsg] = useState('')
  const [spinnerCheck, setSpinnerCheck] = useState(false);

  const toggleSchemaDropDown = () => setSchemaDropDownOpen(prevState => !prevState);
  const handleSchemaDropDownSelect = (e) => {
    setSelectedSchemaType(e);
  }

  const [formState, setFormState] = useState({});
  const [connection, setConnection] = useState({});
  const [connectionId, setConnectionId] = useState("");
  const [phoneNumberNotConnected, setPhoneNumberNotConnected] = useState("");
  const [findConnections, setFindConnections] = useState([]);
  const [isConnectionNotFound, setIsConnectionNotFound] = useState(false);
  const [inputNumber, setInputNumber] = useState('');

  const handleFormState = (identifier, data) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [identifier]: data
      }
    })
  }

  useEffect(() => {
    // const connection = props.location.state?.connection;

    selectedSchemaType.schemaAttributes?.map((data) => {

      if (data.identifier == "name") {
        handleFormState(data.identifier, connection?.name)
      }
      else if (data.identifier == "full_name") {
        handleFormState(data.identifier, connection?.name)
      }
      else if (data.identifier == "Full Name") {
        handleFormState(data.identifier, connection?.name)
      }
      else if (data.identifier == "Name") {
        handleFormState(data.identifier, connection?.name)
      }
      else if (data.identifier == "User Id") {
        handleFormState(data.identifier, connection?.userId)
      }
      else {
        if (data?.default == undefined)
          handleFormState(data.identifier, "")
        else
          handleFormState(data.identifier, data?.default)
      }

    })

  }, [connection, selectedSchemaType])

  useEffect(() => {
    if (props.location.state) {
      setConnection(props.location.state.connection);
    }
  }, [])

  const handleFormSubmit = (e) => {

    let authRole = Auth.getAuth();

    e.preventDefault();

    if (selectedSchemaType.schemaAttributes) {

      let shouldSubmit = true;

      if (!phoneNumberNotConnected && Object.keys(connection).length === 0) {

        toast.error(`Connection Id is empty.`, {
          position: "top-left",
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return false;

      }

      selectedSchemaType.schemaAttributes?.map((data) => {

        if (formState[data.identifier] == undefined || formState[data.identifier] == null || formState[data.identifier] == "") {

          shouldSubmit = false;

          return toast.error(`${data.title} is empty.`, {
            position: "top-left",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }

      })

      if (!shouldSubmit) return false;

    } else {

      console.log("schema not loaded.")
      return false;

    }

    setSpinnerCheck(true);

    setIsLoading(true);

    setModalIsOpen(true);

    if (!isConnectionNotFound && !(authRole && PermissionsGate({ isFunction: true, hasPermission: [PERMISSIONS.send_apporval_req] }) && !PermissionsGate({ isFunction: true, hasPermission: ['All'] }))) {
      issueCredential(formState, selectedSchemaType);
    }

    if (isConnectionNotFound || (authRole && PermissionsGate({ isFunction: true, hasPermission: [PERMISSIONS.send_apporval_req] }) && !PermissionsGate({ isFunction: true, hasPermission: ['All'] }))) {
      saveCredentialForAutoIssuance(formState, selectedSchemaType)
    }

  }

  const clearCustomNumber = () => {
    setIsConnectionNotFound(false);
    setPhoneNumberNotConnected('');
    setFindConnections([]);
  }

  const handleNumberChange = (e) => {
    let phone = e.target.value;

    setInputNumber(phone);
  }

  const issueCredential = (formState, selectedSchemaType) => {

    axios.post(`${process.env.REACT_APP_API_URL}/api/issue_single_credentials`,
      {
        subject: localStorage.getItem('subject'),
        passObject: formState,
        phone: connection?.phone,
        connectionId: connection?.connectionId,
        tenantId: selectedSchemaType.tenantId,
        schemaId: selectedSchemaType.schemaId,
        tz: moment.tz.guess()
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
      }).then((data) => {
        setModalIsOpen(false);
        setIsLoading(false);
        if (data.data.success) {
          setModalOKIsOpen(true);
          setFormState({});
          setConnectionId('');
          setConnection('');
          setInputNumber('');
        } else {
          setModalNotOKIsOpen(true);
          setModalNotOKayErrMsg(data.data.error)
        }
        setSpinnerCheck(false);

      }).catch((err) => {
        setModalIsOpen(false);
        setSpinnerCheck(false);
        setModalNotOKIsOpen(true);
        setModalNotOKayErrMsg(err.message);
        setIsLoading(false);
      })

  }

  const saveCredentialForAutoIssuance = (formState, selectedSchemaType) => {

    let authRole = Auth.getAuth();

    axios.post(`${process.env.REACT_APP_API_URL}/api/auto_issue_credentials`,
      {
        passObject: formState,
        connection,
        phone: (authRole && PermissionsGate({ isFunction: true, hasPermission: [PERMISSIONS.send_apporval_req] }) && !PermissionsGate({ isFunction: true, hasPermission: ['All'] }) && !phoneNumberNotConnected && connection) ? connection?.phone : phoneNumberNotConnected,
        tenantId: selectedSchemaType.tenantId,
        schemaId: selectedSchemaType.schemaId,
        tz: moment.tz.guess()
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') }
      }).then((data) => {

        if (authRole && PermissionsGate({ isFunction: true, hasPermission: [PERMISSIONS.send_apporval_req] }) && !PermissionsGate({ isFunction: true, hasPermission: ['All'] })) {
          toast.success(`Credential Details Send for Approval`);
        } else {
          toast.success(`Credential Details Saved`);
        }

        setFormState({});
        setConnectionId('');
        setConnection('');
        setPhoneNumberNotConnected('');
        setSpinnerCheck(false);
        setIsLoading(false);
        clearCustomNumber();
        setInputNumber('');


      }).catch((err) => {

        toast.error(`Error: Try Again`);
        setSpinnerCheck(false);
        setIsLoading(false);

      })

  }

  const findConnection = async () => {

    if (!inputNumber) return false;

    setIsLoadingFindConnection(true)

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/filter_connection`,
      {
        params: { phone: inputNumber },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
      }
    );

    if (response.data.status == 200 && response.data.connection.length > 0) {
      setFindConnections(response.data.connection);
      setIsConnectionNotFound(false);
    }

    if (response.data.status == 200 && response.data.connection.length == 0) {
      setIsConnectionNotFound(true);
      setConnection('');
      setConnectionId('');
      setFindConnections([]);
    }

    setIsLoadingFindConnection(false)

  }

  const findUserForAuthCred = async (connection) => {

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_userid_from_user`,
      {
        params: { phone: connection.phone ? connection.phone : connection },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('itoken') },
      }
    );

    let userId = '';

    if (response.data.message == 'success') {
      userId = response.data.connection.userId;
      setConnection({ ...connection, userId });
    }
    else {
      setConnection({ ...connection, userId });
    }

  }

  useEffect(async () => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/schemas`,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('itoken') },
          params: { subject: localStorage.getItem('subject') }
        })

      // todo: dynamic inital schema selection
      const schemas = resp.data.schemas;
      setAvailableSchemas(schemas);
      for (var i = 0; i < schemas.length; i++) {
        if (schemas[i]?.disabled == undefined || !schemas[i]?.disabled) {
          setSelectedSchemaType(schemas[i])
          break;
        }
      }

      // setSelectedSchemaType(schemas[0])
    } catch (err) {
      console.error({ err })
    }
  }, [])

  function closeModal() {
    setModalOKIsOpen(false);
    setModalNotOKIsOpen(false);
  }

  const [modalOKIsOpen, setModalOKIsOpen] = useState(false);
  const [modalNotOKIsOpen, setModalNotOKIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFindConnection, setIsLoadingFindConnection] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [disabledVal, setDisabledVal] = useState(connectionId ? "" : "");

  const setSelectedConnection = async (conn) => {
    setConnection(conn);
    setInputNumber(conn.phone)
    setFindConnections([]);
    findUserForAuthCred(conn);

  }

  const setSelectedNumberAutoIssuance = async () => {

    let phone = inputNumber;

    if (phone.startsWith("+")) {
      phone = phone.substring(1);;
    }

    if (!isValidPhoneNumber('+' + phone)) {
      toast.error('Invalid phone number');
      return false;
    }

    setPhoneNumberNotConnected(phone);
    findUserForAuthCred(phone);

  }


  const searchCustomerNumber = (e) => {
    if (e.keyCode === 13) {
      findConnection()
    }
  }

  return (
    <>
      <ToastContainer />

      <CredHeader heading={"Single Issuance"} />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay">

          <div className="centered-content d-flex justify-content-center">
            <h4>{issueSingleCredentials_screen.modal_OK_heading}</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>{issueSingleCredentials_screen.modal_OK_message}</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={
                require("../assets/img/theme/check.png")
                  .default
              }
            />
          </div>
          <br />
          <Button
            color="success"
            style={{ width: "100%" }}
            onClick={closeModal}
          >
            {issueSingleCredentials_screen.modal_OK_button_continue}
          </Button>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalNotOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay">

          <div className="centered-content d-flex justify-content-center">
            <h4>{issueSingleCredentials_screen.modal_NOK_heading}</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            {/* <p>Unable to Issue Certificate</p> */}
            <p>{modalNotOKayErrMsg}</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={
                require("../assets/img/theme/cross.png")
                  .default
              }
            />
          </div>
          <br />
          <Button

            style={{ width: "100%", backgroundColor: "#c82606", color: "#ffffff" }}
            onClick={closeModal}
          >
            {issueSingleCredentials_screen.modal_NOK_button_continue}
          </Button>
        </Modal>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{issueSingleCredentials_screen.sub_heading}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <>
                  {phoneNumberNotConnected && <span style={{ color: "green" }}>{issueSingleCredentials_screen.phone_message1}</span>}
                  {!phoneNumberNotConnected && isConnectionNotFound && <span style={{ color: "red" }}>{issueSingleCredentials_screen.phone_message2}</span>}
                  <div className="d-flex">
                    <div class="w-100 suggestions-container">
                      <Input placeholder={issueSingleCredentials_screen.textbox_placeholder} value={inputNumber} className="rounded-none" type="text"
                        onKeyUp={clearCustomNumber}
                        onChange={handleNumberChange}
                        onKeyDown={(e) => searchCustomerNumber(e)}
                      />
                      {findConnections.length > 0 &&
                        <div class="suggestions">
                          <div role="listbox">
                            {
                              findConnections?.map((data) => {
                                return (
                                  <div role="option" onClick={() => { setSelectedConnection(data) }}>{data.phone}</div>
                                )
                              })
                            }

                          </div>
                        </div>}
                    </div>
                    {isConnectionNotFound ?
                      <Button className="rounded-end" color="primary" disabled={isLoadingFindConnection}
                        onClick={setSelectedNumberAutoIssuance}>
                        {issueSingleCredentials_screen.button_add}
                      </Button> :
                      <Button className="rounded-end" color="primary" disabled={isLoadingFindConnection}
                        onClick={findConnection}>
                        {issueSingleCredentials_screen.button_find}
                      </Button>
                    }

                  </div>

                </>
                <Form onSubmit={handleFormSubmit}>
                  {disabledVal && <span style={{ color: "red", fontSize: "19px" }} >{issueSingleCredentials_screen.form_notification}</span>}

                  <div style={{ marginTop: 20 }}>
                    <label className="form-control-label" htmlFor="input-schema-type">
                      <h2>{issueSingleCredentials_screen.form_sub_heading}</h2>
                    </label>

                    <div id="input-schematype">
                      <Dropdown disabled={isLoading} isOpen={schemaDropDownOpen} toggle={toggleSchemaDropDown}>
                        <DropdownToggle color="white" caret className="dropdown">
                          {selectedSchemaType?.schemaName}
                        </DropdownToggle>
                        <DropdownMenu className="menu-dropdown">
                          {availableSchemas?.map((data) => {

                            if (data?.disabled == undefined || !data?.disabled) {
                              return <DropdownItem onClick={() => handleSchemaDropDownSelect(data)}>{data.schemaName}</DropdownItem>
                            }

                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="row my-3">
                    {selectedSchemaType.schemaAttributes ?
                      (<>
                        {phoneNumberNotConnected ?
                          <FormGroup className="mb-2 me-sm-2 mb-sm-0 col-md-6">
                            <Label
                              className="me-sm-2"
                              for={"input-" + "phone"}
                            >
                              {issueSingleCredentials_screen.form_label_phone}
                            </Label>
                            <Input
                              id={"input-" + "phone"}
                              key={"phone"}
                              name={"phone"}
                              value={phoneNumberNotConnected}
                              // onChange={(e) => setConnectionId(e.target.value)}
                              type={"string"}
                              disabled
                            />
                          </FormGroup> :
                          <FormGroup className="mb-2 me-sm-2 mb-sm-0 col-md-6">
                            <Label
                              className="me-sm-2"
                              for={"input-" + "connectionId"}
                            >
                              {issueSingleCredentials_screen.form_label_connectionID}
                            </Label>
                            <Input
                              id={"input-" + "connectionId"}
                              key={"connectionId"}
                              name={"connectionId"}
                              value={connection ? connection.connectionId : ""}
                              // onChange={(e) => setConnectionId(e.target.value)}
                              type={"string"}
                              disabled
                            />
                          </FormGroup>
                        }
                        {selectedSchemaType && selectedSchemaType.schemaAttributes?.map((data) => {
                          return (
                            <>
                              <FormGroup className="mb-2 me-sm-2 mb-sm-0 col-md-6">
                                <fieldset disabled={disabledVal}>
                                  <Label
                                    className="me-sm-2"
                                    for={"input-" + data.identifier}
                                  >
                                    {data.title}
                                  </Label>

                                  {inputTypes[data.type] == "select" ?
                                    <Select
                                      key={data}
                                      name={data.identifier}
                                      id={"input-" + data.identifier}
                                      value={formState[data.identifier] ?? data?.default}
                                      options={data?.options}
                                      identifier={data.identifier}
                                      onChange={handleFormState}
                                    /> :
                                    <Input
                                      id={"input-" + data.identifier}
                                      key={data}
                                      name={data.identifier}
                                      value={formState[data.identifier] ?? data?.default}
                                      onChange={(e) => handleFormState(data.identifier, e.target.value)}
                                      type={inputTypes[data.type]}
                                      disabled={data.identifier == "User Id" ? true : isLoading}
                                    />
                                  }

                                </fieldset>
                              </FormGroup>

                            </>
                          )
                        })}
                      </>) : null
                    }
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                    </Col>
                    <Col className="text-right" xs="4">

                      <Button
                        color="primary"
                        disabled={isLoading}
                      // onClick={submit}
                      >
                        {!spinnerCheck ? ("Sign & Issue") : <div>{issueSingleCredentials_screen.notification_issuing}  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">{issueSingleCredentials_screen.loading}</span></div>}
                      </Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Credential;
